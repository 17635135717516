export const SET_TOPICS = "SET_TOPICS";
export const SET_TOP_ARTICLES = "SET_TOP_ARTICLES";
export const SET_TOP_WORDS = "SET_TOP_WORDS";

export const ADD_COLOR = "ADD_COLOR";
export const REMOVE_COLOR = "REMOVE_COLOR";
export const REMOVE_ALL_COLORS = "REMOVE_ALL_COLORS";

export const SET_HIGHLIGHTED_WORDS = "SET_HIGHLIGHTED_WORDS";
export const ADD_HIGHLIGHTED_WORD = "ADD_HIGHLIGHTED_WORD";
export const REMOVE_HIGHLIGHTED_WORD = "REMOVE_HIGHLIGHTED_WORD";
export const CLEAR_HIGHLIGHTED_WORDS = "CLEAR_HIGHLIGHTED_WORDS";

export const SET_ZOOM = "SET_ZOOM";
export const SET_SELECTION_POSITION = "SET_SELECTION_POSITION";
export const GO_HOME = "GO_HOME";
export const PAN_TO = "PAN_TO";

export const TOGGLE_CENTERS_VISIBILITY = "TOGGLE_CENTERS_VISIBILITY";
export const TOGGLE_FILTERS_SIDEBAR_VISIBILITY = "TOGGLE_FILTERS_SIDEBAR_VISIBILITY";
export const TOGGLE_TOPICS_SIDEBAR_VISIBILITY = "TOGGLE_TOPICS_SIDEBAR_VISIBILITY";

export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_SELECTED_TAG = "SET_SELECTED_TAG";
export const SET_SELECTED_DOCUMENT = "SET_SELECTED_DOCUMENT";
export const PUSH_TO_PREVIOUS_SEARCH = "ADD_TO_PREVIOUS_SEARCH";

export const GENERATE_COLOR_MAP = "GENERATE_COLOR_MAP";
export const CHANGE_PAGINATION = "CHANGE_PAGINATION";

export const SHOW_LOADING = "SHOW_LOADING";
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const TOGGLE_TEST_MODE = "TOGGLE_TEST_MODE";

export const BLOCK_UI_COORDS_REQUEST = "BLOCK_UI_COORDS_REQUEST";
