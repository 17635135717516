import React from 'react';
import PropTypes, {object} from 'prop-types';
import { connect } from 'react-redux';
import Chip from '../../../shared/chip';
import GrowingInput from './components/growing-input';
import {changePagination, getCoords, setSelectedTag} from '../../../../actions';

import './styles.css';

import configs from '../../../../configs';
const { DOCUMENTS_PER_PAGE } = configs.PARAMETERS;


class SearchHeader extends React.Component {

    // TODO Make an action to avoid code duplication
    handleClick(tag) {
        this.props.changePagination(0, DOCUMENTS_PER_PAGE - 1);
        this.props.setSelectedTag(tag);
    }

    removeHighlighting(text) {
        const begin = new RegExp(`(%\\$)`, 'gi');
        const end = new RegExp(`(\\$%)`, 'gi');
        return text.replace(begin, '').replace(end, '');
    }

    renderSelectedTagChip() {
        return (
            <Chip
                color={this.props.colorMap[this.props.selectedTag.id]}
                text={this.props.selectedTag.name}
                hasClearButton={!this.props.isMobile}
                handleClick={this.props.isMobile ? () => this.props.handleClearTag() : () => {}}
                handleClearClick={() => this.props.handleClearTag()}
                styles={{ border: 'none', maxWidth: 326 }}
            />
        );
    }

    renderSelectedDocumentChip() {
        const title = this.removeHighlighting(this.props.selectedDocument.title);
        return (
            <Chip
                color="orange"
                title={title}
                text={title.length > 40 ? title.substring(0,40) + '...' : title}
                hasClearButton={!this.props.isMobile}
                handleClick={this.props.isMobile ? () => this.props.handleClearDocument() : () => {}}
                handleClearClick={() => this.props.handleClearDocument()}
                styles={{ border: 'none', maxWidth: 326 }}
            />
        );
    }

    render() {
        return (
            <header className="pt-3 search-header">
                <GrowingInput
                    handleOnSubmit={this.props.handleOnSubmit}
                    handleOnChange={this.props.handleOnChange}
                    handleClearSearch={this.props.handleClearSearch}
                    query={this.props.query}
                />
                {!this.props.selectedTag && this.props.isMobile &&
                <div className="tag-panel-mobile">
                    {this.props.tags.map(tag => (
                            <Chip
                                key={tag.id} // be careful with that man, one day they'll send not unique id...
                                text={tag.name}
                                handleClick={() => this.handleClick(tag)}
                                color={null}
                                handleClearClick={() => {}}
                            />
                        ))}
                </div>
                }
                <div className="d-flex">
                    { this.props.selectedTag && this.renderSelectedTagChip() }
                    { this.props.selectedDocument && this.renderSelectedDocumentChip() }
                </div>
            </header>
        );
    }
}

SearchHeader.propTypes = {
    query: PropTypes.string,
    handleOnSubmit: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
    handleClearDocument: PropTypes.func.isRequired,
    handleClearTag: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(object),
    searchValue: PropTypes.string.isRequired,
    selectedDocument: PropTypes.object,
    selectedTag: PropTypes.object,
    colorMap: PropTypes.object,
    isMobile: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    tags: state.search.tags,
    searchValue: state.search.searchValue,
    selectedDocument: state.search.selectedDocument,
    selectedTag: state.search.selectedTag,
    colorMap: state.search.colorMap,
    pageFrom: state.search.pageFrom,
    pageTo: state.search.pageTo,
    testMode: state.search.testMode
});

export default connect(mapStateToProps, { setSelectedTag, getCoords, changePagination })(SearchHeader);
