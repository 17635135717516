import React from 'react';
import PropTypes from 'prop-types';
import Chip from '../../../shared/chip';
import ItemsContainer from "../../../shared/items-container";

import { v4 } from 'node-uuid';

class RecentSearch extends React.Component {
    render() {
        return (
            <div>
                <h4 className="ml-2">Recent search</h4>
                <div className="ml-1">
                    <ItemsContainer styles={{ height: '90%' }}>
                    { this.props.recentSearch.map(query =>
                        <Chip
                            text={query}
                            key={v4()}
                            handleClick={() => this.props.handleRecentSearch(query)}
                            styles={{ borderRadius: 10, background: 'rgba(230, 230, 230, 0.3)', border: 'none', padding: '1rem 2rem' }}
                        />
                        )}
                    </ItemsContainer>
                </div>
            </div>
        );
    }
}

RecentSearch.propTypes = {
    recentSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleRecentSearch: PropTypes.func.isRequired
};

export default RecentSearch;
