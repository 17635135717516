import { store } from 'react-notifications-component';
import reduxStore  from './store';
import {HIDE_ERROR} from "../actions/actionTypes";

export const notify = ({ title, message,  type }) => {
    store.addNotification({
        title: title,
        message,
        type: type,
        insert: "top",
        container: "bottom-left",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
        },
        onRemoval: () => reduxStore.dispatch({ type: HIDE_ERROR })
    });
};
