import {HIDE_ERROR, SHOW_ERROR} from "../actions/actionTypes";

const initialState = {
    error: null,
    show: false
};
Object.freeze(initialState);

export function errorReducer(state = initialState, action){
    switch (action.type) {
        case HIDE_ERROR:
            return initialState;
        case SHOW_ERROR:
            const { error } = action.payload;
            if (!error) return initialState;
            return {
                error,
                show: true
            };
        default:
            return state;
    }
}
