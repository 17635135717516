import React from 'react';
import { connect } from 'react-redux';
import {Router, Route, Redirect } from 'react-router-dom';

import Header from './components/header';
import SearchContainer from './components/search-container';
import SemanticMapContainer from './components/semantic-map-container';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import {notify} from './util/notification';
import history from './util/history';

import configs from './configs';
import config from './configs/config';
const { ROUTE_MAP_TYPE, ROUTER_PREFIX } = configs;


class App extends React.PureComponent {
    state = {
        viewportWidth: window.innerWidth,
        isIE: false
    };

    componentDidMount() {
        if (this.isIE()) {
           this.setState({ isIE: true });
        } else {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
            window.addEventListener("resize", this.handleResize);
        }

        const title = document.createElement("title");
        title.innerText = config.title;
        document.head.appendChild(title);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.error) {
            notify({
                title: 'Error',
                message: this.props.error,
                type: 'danger'
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    isIE() {
        return window.navigator.userAgent.match(/(MSIE|Trident)/);
    }

    handleResize = () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        this.setState({ viewportWidth: window.innerWidth });
    };

    isMobile = () => {
        return this.state.viewportWidth < 1024;
    };

    render() {
        if (this.state.isIE) {
            return <h2 className="d-flex justify-content-center align-items-center">
                Sorry, IE 11 and older versions are not supported. Please, use another browser (Chrome, Firefox, Safari, MS Edge).
            </h2>
        }

        // TODO Add a basename instead of prefix
        return (
            <>
                <Router history={history}>
                    <div className="d-flex justify-content-start flex-column h-100">
                        <Route exact path={`${ROUTER_PREFIX}/`}>
                            { !configs.PARAMETERS.HIDE_SEARCH && <Redirect to={`${ROUTER_PREFIX}/search`} /> }
                            { configs.PARAMETERS.HIDE_SEARCH && <Redirect to={`${ROUTER_PREFIX}/map/${configs.PARAMETERS.HIDE_WORDMAP ? 'doc' : 'word'}`} /> }
                        </Route>
                        <Route exact path={`${ROUTER_PREFIX}/search`}  render={({ match }) => <Header match={match} isMobile={this.isMobile()} />} />

                        {
                             !configs.PARAMETERS.HIDE_SEARCH &&
                             <Route exact path={`${ROUTER_PREFIX}/search`}  render={({ match, location }) =>
                                 <SearchContainer
                                     match={match}
                                     location={location}
                                     isMobile={this.isMobile()}
                                 />}
                             />
                        }

                        <Route exact path={ROUTE_MAP_TYPE}  render={({ match }) => <Header match={match} isMobile={this.isMobile()} />} />
                        <Route exact path={ROUTE_MAP_TYPE}  render={({ match }) => <SemanticMapContainer mapType={match.params.mapType} isMobile={this.isMobile()} />} />
                        <ReactNotification />
                    </div>
                </Router>
            </>
        );
    }
}

const mapStateToProps = state => ({
    error: state.error.error
});

export default connect(mapStateToProps)(App);
