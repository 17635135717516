import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from "react-paginate";

import {changePagination} from '../../../../actions';

import './styles.css';

import configs from '../../../../configs';
const { DOCUMENTS_PER_PAGE } = configs.PARAMETERS;

class SearchFooter extends React.Component {
    handleChangePage = ({ selected }) => {
        this.props.changePagination(DOCUMENTS_PER_PAGE * selected, DOCUMENTS_PER_PAGE * (selected + 1) - 1);
    };

    render() {
        return (
            <footer className="search-footer">
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(this.props.totalHits/DOCUMENTS_PER_PAGE)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={this.props.isMobile ? 1 : 3}
                    onPageChange={(args) => this.handleChangePage(args)}
                    forcePage={Math.ceil(this.props.pageFrom/DOCUMENTS_PER_PAGE)}

                    breakClassName={'pagination-item'}
                    breakLinkClassName={'pagination-link'}
                    containerClassName={'pagination'}
                    pageClassName={'pagination-item'}
                    pageLinkClassName={'pagination-link'}
                    previousClassName={'pagination-item'}
                    previousLinkClassName={'pagination-link'}
                    nextClassName={'pagination-item'}
                    nextLinkClassName={'pagination-link'}
                    activeClassName={'pagination-active'}
                />
            </footer>
        );
    }
}

SearchFooter.propTypes = {
    totalHits: PropTypes.number.isRequired,
    pageFrom: PropTypes.number.isRequired,
    isMobile: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    totalHits: state.search.totalHits,
    pageFrom: state.search.pageFrom
});

export default connect(mapStateToProps, { changePagination })(SearchFooter);
