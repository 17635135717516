import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import './styles.css';

class SearchInput extends React.Component {
    state = {
        searchTerm: this.props.value
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setState({ searchTerm: this.props.value });
        }
    }

    isSearchTermEmpty(term) {
        return term.trim().length === 0;
    }

    handleOnChange(event) {
        const newTerm = event.target.value;
        this.setState({ searchTerm: newTerm });
        this.props.handleOnChange(event);
    }

    clearSearch(event) {
        this.setState({ searchTerm: '' });
        this.props.clearSearch(event);
    }

    render() {
        return (
            <div className="form-group has-search">
                <FontAwesomeIcon icon={faSearch} className="form-control-feedback"/>
                <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search..."
                    value={this.state.searchTerm}
                    maxLength={100}
                    onChange={(event) => this.handleOnChange(event)}
                />
                {
                    !this.isSearchTermEmpty(this.state.searchTerm) ? (
                        <span className="clear-search" onClick={(event) => this.clearSearch(event)}>&times;</span>
                    ) : null
                }
            </div>
        );
    }
}

SearchInput.propTypes = {
    handleOnChange: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default SearchInput;
