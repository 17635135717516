import {
    TOGGLE_CENTERS_VISIBILITY,
    TOGGLE_FILTERS_SIDEBAR_VISIBILITY,
    TOGGLE_TOPICS_SIDEBAR_VISIBILITY
} from '../actions/actionTypes';

const initialState = {
    filtersSidebarVisible: true,
    topicsSidebarVisible: true,
    centersVisible: true,
    isMobile: false
};

export default (state=initialState, action) => {
    switch (action.type) {
        case TOGGLE_FILTERS_SIDEBAR_VISIBILITY:
            return {
                ...state,
                filtersSidebarVisible: action.payload.isVisible
            };

        case TOGGLE_TOPICS_SIDEBAR_VISIBILITY:
            return {
                ...state,
                topicsSidebarVisible: action.payload.isVisible
            };

        case TOGGLE_CENTERS_VISIBILITY:
            return {
                ...state,
                centersVisible: action.payload.isVisible
            };

        default:
            return state;
    }
};
