import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArticlesContainer from './components/articles-container';
import KeywordsContainer from './components/keywords-container';
import { toggleTopicsSidebarVisibility } from '../../actions';
import './styles.css';
import {Route} from "react-router-dom";

import configs from '../../configs';
const { ROUTE_MAP_TYPE } = configs;
const { WORDMAP, DOCMAP, SEARCH } = configs.CONSTANTS;

class TopicsSidebar extends React.Component {
    render() {
        const notVisible = !this.props.topicsSidebarVisible || this.props.isMobile;

        if (this.props.isMobile) {
            return <span />;
        }

        return (
            <>
                <div className="topics-sidebar" style={notVisible ? { display: 'none', minWidth: 0, flex: '0 0 auto' } : {}}>
                    <div className="d-flex justify-content-start align-items-center text-white" style={{ flex: '0 0 auto'}}>
                        <h3
                            onClick={() => this.props.toggleTopicsSidebarVisibility(false)}
                            className="collapse-button m-2"
                        >☰</h3>
                        <h3 className="uppercase m-2">Area explorer</h3>
                    </div>
                    <hr className="divider" />
                    <Route path={ROUTE_MAP_TYPE} render={({ match }) => <KeywordsContainer match={match} />} />
                    <hr className="divider" />
                    <ArticlesContainer mapType={this.props.mapType} />
                </div>
                { notVisible ? (
                    <div className="collapsed-toolbar m-0" style={{ top: 0, right: 17 }}>
                        <h3
                            onClick={() => this.props.toggleTopicsSidebarVisibility(true)}
                            className="collapse-button mr-1 mb-2"
                        >☰</h3>
                        <h3 className="uppercase">Area explorer</h3>
                    </div>
                ) : null}
            </>
        );
    }
}

TopicsSidebar.propTypes = {
    mapType: PropTypes.oneOf([ WORDMAP, DOCMAP, SEARCH ]).isRequired,
    topicsSidebarVisible: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    toggleTopicsSidebarVisibility: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    topicsSidebarVisible: state.visibility.topicsSidebarVisible
});

export default connect(mapStateToProps, { toggleTopicsSidebarVisibility })(TopicsSidebar);
