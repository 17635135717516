import React from 'react';
import './styles.css';

class ArticleDetails extends React.PureComponent {
    render() {
        return (
            <span className="article-details mb-2 ml-1">
                <a href={this.props.article.url || 'javascript:void(0);'}
                   target={this.props.article.url ? "_blank" : "_self"}
                   rel="noopener noreferrer"
                >
                    <div className="line-clamp-2">{ this.props.article.title }</div>
                    <div className="url-details truncated">{ this.props.article.url }</div>
                </a>
            </span>
        );
    }
}

export default ArticleDetails;
