import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 } from 'node-uuid';

import KeywordDetails from '../topics-sidebar/components/keywords-container/components/keyword-details';
import {
    clearHighlightedWords,
    goHome,
    removeAllColors,
    removeHighlightedWord,
    toggleCentersVisibility
} from '../../actions';

import showLabelsIcon from '../../images/showLabelsIcon.svg';
import noLabelIcon from '../../images/noLabelsIcon.svg';
import homeIcon from '../../images/homeIcon.svg';
import trashIcon from '../../images/trashIcon.svg';

import './styles.css';

import configs from '../../configs';
const { WORDMAP, DOCMAP } = configs.CONSTANTS;


class HighlightedTermsContainer extends React.Component {
    handleUnselect() {
        this.props.removeAllColors(this.props.mapType);
        this.props.clearHighlightedWords(this.props.mapType);
    }

    render() {
        if (this.props.isMobile) {
            return (
                <div
                    className="highlighted-terms-container text-white d-flex justify-content-center p-1 h-auto text-center"
                    style={{ minHeight: 50 }}
                >
                    Functionality is limited. Please try device with wider screen, such as landscape-oriented tablet or laptop.
                </div>);
        }

        const isHighlighted = this.props.highlightedWords && this.props.highlightedWords.length;
        const showTooltip = 'Show topic labels';
        const hideTooltip = 'Hide topic labels';
        const zoomOutTooltip = 'Zoom to whole map';
        const removeAllTooltip = 'Deselect all keywords';

        return (
            <div className="highlighted-terms-container">
                {
                    !configs.PARAMETERS.HIDE_TOPICS &&
                    <img
                        src={this.props.centersVisible ? noLabelIcon : showLabelsIcon}
                        alt="Show/hide labels"
                        className="ml-2 mr-2"
                        title={this.props.centersVisible ? hideTooltip : showTooltip}
                        onClick={() => this.props.toggleCentersVisibility(!this.props.centersVisible)}
                    />
                }
                <img
                    src={homeIcon}
                    alt="Go home"
                    className="mr-2"
                    title={zoomOutTooltip}
                    onClick={() => this.props.goHome(true)}
                />
                <div>
                { isHighlighted ?
                    this.props.highlightedWords
                        .slice(0)
                        .reverse()
                        .map(highlightedWord => (
                        <KeywordDetails
                            key={v4()}
                            word={highlightedWord.keyword}
                            isSelected={true}
                            highlightedWord={[highlightedWord]}
                            hasClearButton={true}
                            mapType={this.props.mapType}
                        />)) : null
                }
                </div>
                {
                    isHighlighted ?
                    <img
                        src={trashIcon}
                        alt="Unselect highlighting"
                        className="ml-auto"
                        title={removeAllTooltip}
                        onClick={() => this.handleUnselect()}
                    /> : null
                }
            </div>
        )
    }
}

HighlightedTermsContainer.propTypes = {
    mapType: PropTypes.oneOf([WORDMAP, DOCMAP]),
    highlightedWords: PropTypes.arrayOf(
        PropTypes.shape(
            { keyword: PropTypes.string,
                color: PropTypes.string,
                coords: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }))}
                )
    ),
    centersVisible: PropTypes.bool,
    isMobile: PropTypes.bool,
    removeHighlightedWord: PropTypes.func,
    clearHighlightedWords: PropTypes.func,
    removeAllColors: PropTypes.func,
    goHome: PropTypes.func,
    toggleCentersVisibility: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    mapType: ownProps.match.params.mapType,
    highlightedWords: state[ownProps.match.params.mapType].highlightedWords,
    centersVisible: state.visibility.centersVisible
});

export default connect(mapStateToProps, { removeHighlightedWord,
                                          clearHighlightedWords,
                                          removeAllColors,
                                          goHome,
                                          toggleCentersVisibility })(HighlightedTermsContainer);

