import OpenSeadragon from 'openseadragon';

class Overlay {
    constructor(viewer, options) {
        this._viewer = viewer;
        this._containerWidth = 0;
        this._containerHeight = 0;

        this._canvasdiv = document.createElement('div');
        this._canvasdiv.id = "canvas-div";
        this._canvasdiv.style.position = 'absolute';
        this._canvasdiv.style.left = 0;
        this._canvasdiv.style.top = 0;
        this._canvasdiv.style.width = '100%';
        this._canvasdiv.style.height = '100%';
        this._viewer.canvas.appendChild(this._canvasdiv);

        this._canvas = document.createElement('canvas');
        this._canvasdiv.appendChild(this._canvas);

        this.onRedraw = options.onRedraw || (() => {});
        this.clearBeforeRedraw = (typeof (options.clearBeforeRedraw) !== "undefined") ?
            options.clearBeforeRedraw : true;

        this._viewer.addHandler('update-viewport', () => {
            this.resize();
            this._updateCanvas();
        });

        this._viewer.addHandler('open', () => {
            this.resize();
            this._updateCanvas();
        });
    }

    canvas = () => {
        return this._canvas;
    };

    context2d = () => {
        return this._canvas.getContext('2d');
    };

    clear = () => {
        this._canvas.getContext('2d').clearRect(0, 0, this._containerWidth, this._containerHeight);
    };

    resize = () => {
        if (this._containerWidth !== this._viewer.container.clientWidth) {
            this._containerWidth = this._viewer.container.clientWidth;
            this._canvasdiv.setAttribute('width', this._containerWidth);
            this._canvas.setAttribute('width', this._containerWidth);
        }

        if (this._containerHeight !== this._viewer.container.clientHeight) {
            this._containerHeight = this._viewer.container.clientHeight;
            this._canvasdiv.setAttribute('height', this._containerHeight);
            this._canvas.setAttribute('height', this._containerHeight);
        }
        this._viewportOrigin = new OpenSeadragon.Point(0, 0);
        const boundsRect = this._viewer.viewport.getBoundsNoRotate(true);
        this._viewportOrigin.x = boundsRect.x;
        this._viewportOrigin.y = boundsRect.y; //* this.imgAspectRatio;

        this._viewportWidth = boundsRect.width;
        this._viewportHeight = boundsRect.height * this.imgAspectRatio;
        const image1 = this._viewer.world.getItemAt(0);
        this.imgWidth = image1.source.dimensions.x;
        this.imgHeight = image1.source.dimensions.y;
        this.imgAspectRatio = this.imgWidth / this.imgHeight;
    };

    _updateCanvas = () => {
        const viewportZoom = this._viewer.viewport.getZoom(true);
        const scale = viewportZoom * this._containerWidth;
        const context = this._canvas.getContext('2d');
        const centerX = this._canvas.width / 2;
        const centerY = this._canvas.height / 2;
        const degrees = this._viewer.viewport.getRotation();
        const DEG2RAD = Math.PI / 180;

        const x =
            ((this._viewportOrigin.x / this.imgWidth - this._viewportOrigin.x) / this._viewportWidth) *
            this._containerWidth;
        const y =
            ((this._viewportOrigin.y / this.imgHeight - this._viewportOrigin.y) /
                this._viewportHeight) *
            this._containerHeight;

        if (this.clearBeforeRedraw) {
            this.clear();
        }

        context.translate(centerX, centerY);
        context.rotate(degrees * DEG2RAD);
        context.translate(-centerX, -centerY);

        context.translate(x, y);
        context.scale(scale, scale);
        this.onRedraw();
        context.setTransform(1, 0, 0, 1, 0, 0);
    };

    close = () => {
        document.getElementById('canvas-div').remove();
    };
}

export default Overlay;

