import React from 'react';
import {connect} from 'react-redux';
import { v4 } from 'node-uuid';

import KeywordDetails from './components/keyword-details';
import infoIcon from '../../../../images/infoIcon.svg';
import '../../styles.css';

import configs from '../../../../configs';
const { WORDMAP } = configs.CONSTANTS;

class KeywordsContainer extends React.PureComponent {
    render() {
        const keywords = this.props.mapType === WORDMAP ? 'Top keywords' : 'Top topics';
        const tooltip = `${this.props.mapType === WORDMAP ? 'Words' : 'Topics'} closely related to a selection box. 
Click on the ${this.props.mapType === WORDMAP ? 'word' : 'topic name'} to see its usage across the map.`;

        return (
            <div className="wrapper" style={{ height: 'auto', maxHeight: 'calc(50% - 67px)', flex: '0 0 auto' }}>
                <div className="d-flex align-items-center" style={{ flex: '0 0 auto' }}>
                    <h4 className="ml-1 mr-2 mt-0 mb-0">{ keywords }</h4>
                    <img src={infoIcon} alt="Info" title={tooltip} />
                </div>
                <div className="items-container" style={!this.props.words || !this.props.words.length ? { alignContent: 'center' } : { height: 'auto' }}>
                    { this.props.words && this.props.words.length ?
                        this.props.words.map((word, idx) =>
                             <KeywordDetails
                                key={v4()}
                                word={word}
                                isSelected={this.props.highlightedWords.map(el => el.keyword).includes(word)}
                                highlightedWord={this.props.highlightedWords.filter(el => el.keyword === word)}
                                mapType={this.props.mapType}
                             />) :
                        <div
                            className="d-flex justify-content-center align-items-center w-100 text-white"
                        >No keywords for this area.</div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    mapType: ownProps.match.params.mapType,
    words: state[ownProps.match.params.mapType].words,
    highlightedWords: state[ownProps.match.params.mapType].highlightedWords,
});

export default connect(mapStateToProps)(KeywordsContainer);
