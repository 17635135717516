import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import history from '../../util/history';

import SemanticMap from '../shared/semantic-map';
import SearchResult from './components/search-result';
import TagPanel from './components/tag-panel';
import RecentSearch from './components/recent-search';
import LoadingIndicator from '../shared/loading-indicator';
import SearchHeader from './components/search-header';
import SearchFooter from './components/search-footer';


import {
    changePagination,
    clearHighlightedWords,
    getCoords,
    search,
    setSearchResults,
    setSearchValue,
    setSelectedDocument,
    setSelectedTag, setTestMode
} from '../../actions';

import arrowUp from '../../images/arrowUp.svg';
import './styles.css';

import configs from '../../configs';
const { ROUTER_PREFIX } = configs;
const { SEARCH } = configs.CONSTANTS;
const { DOCUMENTS_PER_PAGE } = configs.PARAMETERS;


class SearchContainer extends React.Component {
    state = {
        showWelcome: false
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        const queryString = this.props.location.search.substring(1);
        const searchTerm = new URLSearchParams(queryString).get('query') || null;
        const selectedTag = new URLSearchParams(queryString).get('tag') || null;
        const selectedDocument = new URLSearchParams(queryString).get('doc') || null;
        const pageFrom = new URLSearchParams(queryString).get('from') || 0;
        const pageTo = new URLSearchParams(queryString).get('to') || DOCUMENTS_PER_PAGE - 1;
        const testMode = new URLSearchParams(queryString).get('test') || false;

        if (searchTerm || selectedTag || selectedDocument) {
            this.props.search(this.parseQueryString(this.props.location.search.substring(1)),
                              selectedTag,
                              selectedDocument);
        } else {
            this.setState({ showWelcome: true });
        }
        this.props.setSearchValue(searchTerm);
        this.props.changePagination(Number(pageFrom), Number(pageTo));
        if (testMode) {
            this.props.setTestMode(true);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            const parsedQueryString = this.parseQueryString(this.props.location.search.substring(1));
            this.props.search(parsedQueryString);
            this.scrollToTop();
            if (parsedQueryString.tag) {
                this.props.clearHighlightedWords(SEARCH);
                const selectedTag = this.props.tags.filter(tag => String(tag.id) === String(parsedQueryString.tag))[0];
                if (selectedTag && selectedTag.name) {
                    this.props.getCoords(SEARCH, selectedTag.name, [this.props.colorMap[parsedQueryString.tag]]);
                }
            }
            if (parsedQueryString.test) {
                this.props.setTestMode(Boolean(parsedQueryString.test));
            }
            const showWelcome = !parsedQueryString.query && !parsedQueryString.tag && !parsedQueryString.doc;
            this.setState({ showWelcome });
        }

        const paginationChanged = this.props.pageFrom !== prevProps.pageFrom || this.props.pageTo !== prevProps.pageTo;
        const selectedTagChanged = (this.props.selectedTag !== prevProps.selectedTag) &&
                                   (this.props.selectedTag === null || prevProps.selectedTag === null ||
                                   this.props.selectedTag.id !== prevProps.selectedTag.id);
        const selectedDocumentChanged = (this.props.selectedDocument !== prevProps.selectedDocument) &&
                                        (this.props.selectedDocument === null || prevProps.selectedDocument === null ||
                                         this.props.selectedDocument.id !== prevProps.selectedDocument.id);
        const searchValueReseted = (this.props.searchValue !== prevProps.searchValue) && (this.props.searchValue == null);
        const testModeChanged = this.props.testMode !== prevProps.testMode;

        if (searchValueReseted) {
            this.setState({ showWelcome: true });
        }

        if (paginationChanged || selectedTagChanged || selectedDocumentChanged || testModeChanged || searchValueReseted) {
            this.updateHistory();
        }
    }

    parseQueryString(qString) {
        if (qString.length === 0) {
            return { query: null };
        }

        return qString.split('&').map(param => {
            const splitIdx = param.indexOf('=');
            const key = param.substring(0, splitIdx);
            const value = decodeURI(param.substring(splitIdx + 1));
            return { [key]: value };
        }).reduce((prev, curr) => ({...prev, ...curr}), {});
    }

    buildQueryString(params={}) {
        let searchValue = this.props.searchValue;
        if (params && params.query !== null && params.query !== undefined) {
            searchValue = params.query;
        }

        const query = searchValue ? `query=${encodeURIComponent(searchValue)}` : null;
        const tag = this.props.selectedTag !== null ? `tag=${encodeURIComponent(this.props.selectedTag.id)}` : '';
        const doc = this.props.selectedDocument !== null ? `doc=${encodeURIComponent(this.props.selectedDocument.id)}` : '';

        if (!query && !tag && !doc) {
            return '';
        }

        const from = `from=${this.props.pageFrom}`;
        const to = `to=${this.props.pageTo}`;
        let testMode = '';
        if (this.props.testMode) {
            testMode = 'test=true';
        }
        return '?' + [query, tag, doc, from, to, testMode].filter(el => Boolean(el) && el.length > 0).join('&');
    }

    updateHistory(params) {
        history.push(`${ROUTER_PREFIX}/search${this.buildQueryString(params)}`);
    }

    scrollToTop = () => {
        this.ref.current.scrollTop = 0;
        this.ref.current.scrollLeft = 0;
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        this.props.changePagination(0, DOCUMENTS_PER_PAGE - 1);
        this.updateHistory();
    };

    handleOnChange = (event) => {
        const newSearchTerm = event.target.value.trim();
        this.props.setSearchValue(newSearchTerm);
    };

    resetPagination = () => {
        this.props.changePagination(0, DOCUMENTS_PER_PAGE - 1);
    };

    handleTagClick = (tag) => {
        this.props.setSelectedTag(tag);
        this.resetPagination();
    };

    handleFindSimilarClick = (doc) => {
        this.props.setSelectedDocument(doc);
        this.resetPagination();
    };

    handleClearSearch = () => {
        this.props.setSearchValue(null);
        this.props.setSelectedTag(null);
        this.props.setSelectedDocument(null);
        this.props.clearHighlightedWords(SEARCH);
        this.clearSearchResults();
    };

    handleClearTag = () => {
        this.props.setSelectedTag(null);
        this.props.clearHighlightedWords(SEARCH);
        if (this.props.selectedDocument || this.props.searchValue) {
            this.resetPagination();
        } else {
            this.clearSearchResults();
        }
    };

    handleClearDocument = () => {
        this.props.setSelectedDocument(null);
        if (this.props.selectedTag || this.props.searchValue) {
            this.resetPagination();
        } else {
            this.clearSearchResults();
        }
    };

    handleRecentSearch(query) {
        this.props.setSearchValue(query);
        this.updateHistory({ query });
    }

    clearSearchResults() {
        this.props.setSearchResults({ searchResults: [], tags: [], totalHits: 0 });
    }

    renderWelcome() {
        if (this.props.previousSearch.length) {
            return (
                <RecentSearch
                    recentSearch={this.props.previousSearch}
                    handleRecentSearch={(query) => this.handleRecentSearch(query)}
                />
            );
        }
        return (
            <div className="d-flex justify-content-center flex-column align-items-center mt-5">
                <img src={arrowUp} alt="Arrow up" className="mb-5" />
                <h4 className="uppercase mb-3">Start your search</h4>
                <p className="d-flex justify-content-center w-50 text-center" style={{ fontSize: 12 }}>
                    With a semantic map, researchers, clinicians can quickly access required piece of information or
                    research, explore how different terms and topics are connected, embrace vast amounts of data and
                    transform it into structured and instantly applicable knowledge.
                </p>
            </div>);
    }

    renderNoResults() {
        return <h4 className="d-flex justify-content-center uppercase" style={{ fontSize: 20 }}>No results</h4>;
    }

    renderLoading() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <LoadingIndicator />
            </div>
        );
    }


    render() {
        const params =  new URLSearchParams(this.props.location.search.substring(1));
        let query =  params.get('query');
        if (!query) query = '';

        return (
            <div id="search-container">
                <div className="search-results-container" style={this.props.isMobile ? {width: '100%'} : {width: '40%'}}>
                    <SearchHeader
                        query={query}
                        handleClearDocument={this.handleClearDocument}
                        handleClearTag={this.handleClearTag}
                        handleClearSearch={this.handleClearSearch}
                        handleOnSubmit={this.handleOnSubmit}
                        handleOnChange={this.handleOnChange}
                        isMobile={this.props.isMobile}
                    />
                    <main style={{ overflow: 'auto' }} ref={this.ref}>
                        {!this.props.showLoading && this.props.searchResults.map((doc, idx) => (
                            <SearchResult
                                key={idx}
                                {...doc}
                                handleTagClick={(tag) => this.handleTagClick(tag)}
                                handleFindSimilarClick={() => this.handleFindSimilarClick(doc)}
                            />)
                        )}
                        {!this.props.searchResults.length && this.state.showWelcome && this.renderWelcome()}
                        {!this.props.searchResults.length && !this.state.showWelcome && !this.props.showLoading && this.renderNoResults()}
                        {this.props.showLoading &&  this.renderLoading()}
                    </main>
                    {!this.props.showLoading && this.props.searchResults.length && this.props.totalHits > DOCUMENTS_PER_PAGE ?
                        <SearchFooter isMobile={this.props.isMobile} /> : null
                    }
                </div>
                <div style={this.props.isMobile ? { display: 'none'} : { width: '60%' }}>
                    <SemanticMap mapType="search" />
                </div>
                <TagPanel
                    isVisible={this.props.tags.length > 0 && !this.props.isMobile}
                    tags={this.props.tags}
                />
            </div>
        );
    }
}

SearchContainer.propTypes = {
    searchResults: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        info: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired
    })),
    tags: PropTypes.arrayOf(PropTypes.exact({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        relevance: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        part: PropTypes.string.isRequired
    })),
    searchValue: PropTypes.string,
    selectedTag: PropTypes.object,
    totalHits: PropTypes.number,
    colorMap: PropTypes.object.isRequired,
    pageFrom: PropTypes.number.isRequired,
    pageTo: PropTypes.number.isRequired,
    showLoading: PropTypes.bool.isRequired,
    previousSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
    isMobile: PropTypes.bool.isRequired,
    testMode: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    searchResults: state.search.searchResults,
    tags: state.search.tags,
    highlightedWords: state.search.highlightedWords,

    searchValue: state.search.searchValue,
    selectedTag: state.search.selectedTag,
    selectedDocument: state.search.selectedDocument,

    totalHits: state.search.totalHits,
    colorMap: state.search.colorMap,

    pageFrom: state.search.pageFrom,
    pageTo: state.search.pageTo,

    showLoading: state.search.showLoading,
    previousSearch: state.search.previousSearch,
    testMode: state.search.testMode
});

export default withRouter(connect(mapStateToProps, {
    search,
    setSearchValue,
    setSelectedTag,
    setSelectedDocument,
    setSearchResults,
    getCoords,
    clearHighlightedWords,
    changePagination,
    setTestMode })(SearchContainer));
