const env = (key, type, defaultValue) => {
    if (!process.env[key]) return defaultValue;

    const value = process.env[key];
    switch (type) {
        case "string":
            return value;
        case "boolean":
            const lower = value.toLowerCase();
            if (["1", "true", "yes", "y", "ok", "on"].includes(lower)) return true;
            if (["0", "false", "no", "n", "nok", "off"].includes(lower)) return false;
            throw new Error(`Invalid env variable ${key}. Expected a boolean: ${value}`);
        case "number":
            const parsed = parseInt(value);
            if (isNaN(parsed)) throw new Error(`Invalid env variable ${key}. Expected a number: ${value}`);
            return parsed;
        default:
            throw new Error(`Unsupported type ${type} for env variable ${key}. Should be 'string', 'boolean' or 'number'`);
    }
}

export default {
    BASE_URL: env('REACT_APP_BASE_URL', 'string'),
    SEARCH_SERVER_URL: env('REACT_APP_SEARCH_SERVER_URL', 'string'),
    ROUTER_PREFIX: env('REACT_APP_ROUTER_PREFIX', 'string', ''),
    ROUTE_MAP_TYPE: `${process.env.REACT_APP_ROUTER_PREFIX}/map/:mapType`,
    COLORS: ["#E2CC00", "#17C8C8", "#02BF37", "#EB5757",
             "#90CF0B", "#0085FF", "#FF9900", "#44E4A1",
             "#2D9CDB", "#D60000", "#9D6A06", "#83D2FF",
             "#BE8A8A", "#9B51E0", "#8600B5", "#8C919C",
             "#8F1515", "#455F5D", "#46E9DF", "#FF986B"],
    CONSTANTS: {
        KEYWORDS: 'KEYWORDS',
        TOPICS: 'TOPICS',
        HIGHLIGHTED_TERMS: 'HIGHLIGHTED_TERMS',
        WORDMAP: 'word',
        DOCMAP: 'doc',
        SEARCH: 'search',
        ERROR_MSG: 'Oops... \n Something went wrong. \n Data couldn\'t be retrieved from server.'
    },
    PARAMETERS: { // Adjustable parameters, constants after build is done
        SELECTION_WIDTH: 40,
        SELECTION_HEIGHT: 40,
        WORDMAP_IMAGE_WIDTH: 16385,
        WORDMAP_IMAGE_HEIGHT: 16385,
        DOCMAP_IMAGE_WIDTH: 4097,
        DOCMAP_IMAGE_HEIGHT: 4097,
        DOCUMENTS_PER_PAGE: 10,
        SEARCH_HISTORY_CAPACITY: 15,
        HIDE_SEARCH: env('REACT_APP_HIDE_SEARCH', 'boolean', false),
        HIDE_TOPICS: env('REACT_APP_HIDE_TOPICS', 'boolean', false),
        HIDE_DOCMAP: env('REACT_APP_HIDE_DOCMAP', 'boolean', false),
        HIDE_WORDMAP: env('REACT_APP_HIDE_WORDMAP', 'boolean', false),
        HIDE_LOGO: env('REACT_APP_HIDE_LOGO', 'boolean', false),
        HIDE_ABOUT: env('REACT_APP_HIDE_ABOUT', 'boolean', false),
        HIDE_TUTORIAL: env('REACT_APP_HIDE_TUTORIAL', 'boolean', false),
        HIDE_SEND_FEEDBACK: env('REACT_APP_HIDE_SEND_FEEDBACK', 'boolean', false)
    }
}
