import React from 'react';
import './styles.css';

class ItemsContainer extends React.Component {
    render() {
        return (
            <div className="items-container" style={this.props.styles}>
                {this.props.children}
            </div>
        );
    }
}

export default ItemsContainer;
