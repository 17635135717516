import {
    SET_TOPICS,
    SET_HIGHLIGHTED_WORDS,
    SET_TOP_ARTICLES,
    SET_TOP_WORDS,
    ADD_HIGHLIGHTED_WORD,
    REMOVE_HIGHLIGHTED_WORD,
    CLEAR_HIGHLIGHTED_WORDS,
    ADD_COLOR,
    REMOVE_COLOR,
    REMOVE_ALL_COLORS, SET_ZOOM, GO_HOME, PAN_TO, SET_SELECTION_POSITION
} from '../actions/actionTypes';

const initialState = {
    topics: [],
    articles: [],
    words: [],
    highlightedWords: [],
    usedColors: [],

    zoom: 1,
    selectionPosition: { x: 0.5, y: 0.5 },
    panTo: null,
    atHome: false
};

export default (mapType) => (state=initialState, action) => {
    switch (action.type) {
        case SET_TOPICS:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    topics: action.payload.topics
                };
            }
            return state;

        case SET_TOP_ARTICLES:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    articles: action.payload.topArticles,
                };
            }
            return state;

        case SET_TOP_WORDS:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    words: action.payload.topWords
                };
            }
            return state;

        case SET_HIGHLIGHTED_WORDS:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    highlightedWords: action.payload.highlightedWords
                };
            }
            return state;

        case ADD_HIGHLIGHTED_WORD:
            const { word } = action.payload;
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    highlightedWords: state.highlightedWords.concat([word])
                };
            }
            return state;

        case REMOVE_HIGHLIGHTED_WORD:
            const { index } = action.payload;
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    highlightedWords: state.highlightedWords.filter((_, idx) => idx !== index)
                };
            }
            return state;

        case CLEAR_HIGHLIGHTED_WORDS:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    highlightedWords: []
                };
            }
            return state;

        case ADD_COLOR:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    usedColors: state.usedColors.concat([action.payload.color])
                }
            }
            return state;

        case REMOVE_COLOR:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    usedColors: state.usedColors.filter(el => el !== action.payload.color)
                }
            }
            return state;

        case REMOVE_ALL_COLORS:
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    usedColors: []
                }
            }
            return state;

        case SET_ZOOM:
            const { zoom } = action.payload;
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    zoom
                };
            }
            return state;

        case SET_SELECTION_POSITION:
            const { selectionPosition } = action.payload;
            if (action.payload.mapType === mapType) {
                return {
                    ...state,
                    selectionPosition
                }
            }
            return state;

        case GO_HOME:
            return {
                ...state,
                atHome: action.payload.home
            };

        case PAN_TO:
            const { topic } = action.payload;
            return {
                ...state,
                panTo: topic
            };

        default:
            return state;
    }
};
