import React from 'react';
import {connect} from 'react-redux';
import ArticleDetails from './components/article-details';
import { v4 } from 'node-uuid';
import infoIcon from '../../../../images/infoIcon.svg';
import './styles.css';
import '../../styles.css';

class ArticlesContainer extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.topArticles !== prevProps.topArticles) {
            // Didn't not use scrollTo(0,0) since it doesn't work in MS Edge
            this.ref.current.scrollTop = 0;
            this.ref.current.scrollLeft = 0;
        }
    }

    render() {
        const tooltip = `Documents closely related to a selection box. 
Click on a document to open it. `;

        return (
            <div className="wrapper articles-wrapper">
                <div className="d-flex align-items-center" style={{ flex: '0 0 auto' }}>
                    <h4 className="ml-1 mr-2 mt-0 mb-0 header-medium">Documents</h4>
                    <img src={infoIcon} alt="Info" title={tooltip} />
                </div>
                <div
                    ref={this.ref}
                    className="items-container articles-container"
                    style={!this.props.topArticles || !this.props.topArticles.length ? { alignContent: 'center' } : { height: 'auto' }}
                >
                    { this.props.topArticles && this.props.topArticles.length ?
                        this.props.topArticles.map(article => (
                            <ArticleDetails
                                article={article}
                                key={v4()}
                            />)) :
                        <div className="d-flex justify-content-center align-items-center w-100 text-white">No documents for this area.</div> }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    topArticles: state[ownProps.mapType].articles,
});

export default connect(mapStateToProps)(ArticlesContainer);
