import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chip from '../../../shared/chip';
import './styles.css';

const begin = new RegExp(`(%\\$)`, 'gi');
const end = new RegExp(`(\\$%)`, 'gi');

class SearchResult extends React.Component {
    highlight(text) {
        return text.replace(begin, '<strong class="highlight-item">')
                   .replace(end, '</strong>');
    }

    render() {
        return (
            <div className="search-result">
                <a
                    href={this.props.url}
                    className="btn btn-link search-result-url"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {this.props.url}
                </a>
                <div className="search-result-title" dangerouslySetInnerHTML={{ __html: this.highlight(this.props.title)}} />
                <span className="search-result-info" dangerouslySetInnerHTML={{__html: this.highlight(this.props.info)}} />
                <p className="search-result-text mb-0" dangerouslySetInnerHTML={{__html: this.props.highlightings.map(text => this.highlight(text)).join('...')}} />
                <div className="d-flex justify-content-between mt-1">
                    <div className="d-flex">
                        {this.props.tags.map(tag => (
                            <Chip
                                key={tag.id}
                                color={this.props.colorMap[tag.id]}
                                text={tag.name}
                                handleClick={() => this.props.handleTagClick(tag)}
                                handleClearClick={() => {}}
                                styles={{
                                    fontSize: 12,
                                    marginLeft: 1,
                                    border: 'none'
                                }}
                            />
                        ))}
                    </div>
                    <button className="btn btn-link similar-button" onClick={() => this.props.handleFindSimilarClick()}>Find similar</button>
                </div>
            </div>
        );
    }
}

SearchResult.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    highlightings: PropTypes.arrayOf(PropTypes.string).isRequired,
    info: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    handleTagClick: PropTypes.func.isRequired,
    handleFindSimilarClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    colorMap: state.search.colorMap
});

export default connect(mapStateToProps)(SearchResult);
