import {combineReducers} from 'redux';
import mapReducer from './map';
import visibilityReducer from './visibility';
import searchReducer from './search';
import uiBlockReducer from './uiBlock';
import {errorReducer} from './error';

import configs from '../configs';
const { WORDMAP, DOCMAP, SEARCH } = configs.CONSTANTS;


export default combineReducers({
    [WORDMAP]: mapReducer(WORDMAP),
    [DOCMAP]: mapReducer(DOCMAP),
    [SEARCH]: searchReducer,
    visibility: visibilityReducer,
    uiBlock: uiBlockReducer,
    error: errorReducer
});


