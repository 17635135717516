import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import axios from 'axios';
import { buildSearchUrl } from '../../util';

import logo from "../../images/silk-logo.png";
import angryIcon from '../../images/angryIcon.svg';
import dontCareIcon from '../../images/noEmotionsIcon.svg';
import happyIcon from '../../images/happyIcon.svg';
import history from '../../util/history';
import './styles.css';

import config from '../../configs/config';

import configs from '../../configs';
const BASE_URL = configs.BASE_URL;
const { WORDMAP, DOCMAP } = configs.CONSTANTS;
const { ROUTER_PREFIX } = configs;

Modal.setAppElement('#root');


class Header extends React.Component {
    state = {
        aboutIsOpen: false,
        feedbackIsOpen: false,
        successIsOpen: false,
        errorIsOpen: false,
        feedbackEmotion: null,
        feedbackName: '',
        feedbackEmail: '',
        feedback: ''
    };

    aboutStyles = {
        content : {
            top: '50%',
            left: '50%',
            width: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '100%',
            overflow: 'auto',
            zIndex: 17000,
        },
        overlay: {
            zIndex: 16999,
            overflow: 'auto',
            background: 'transparent'
        }
    };

    feedbackStyles = {
        content : {
            top: '50%',
            left: '50%',
            width: '390px',
            height: '480px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 17000,
        },
        overlay: {
            zIndex: 16999,
            overflow: 'auto',
            background: 'transparent'
        }
    };

    successStyles = {
        content : {
            top: '50%',
            left: '50%',
            width: 'auto',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 17000,
        },
        overlay: {
            zIndex: 16999,
            overflow: 'auto',
            background: 'transparent'
        }
    };

    sendFeedback() {
        const data = {
            name: this.state.feedbackName,
            email: this.state.feedbackEmail,
            emotion: this.state.feedbackEmotion,
            text: this.state.feedback,
            domain: window.location.hostname,
        };
        axios.post(`${BASE_URL}/feedback`, data)
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.resetState();
                    this.setState({
                        feedbackIsOpen: false,
                        successIsOpen: true
                    });
                } else {
                    this.setState({ errorIsOpen: true });
                }
            })
            .catch(err => {
                console.error(err);
                console.trace();
                this.setState({ errorIsOpen: true  });
            });
    }

    resetState() {
        this.setState({
            feedbackName: '',
            feedbackEmail: '',
            feedbackEmotion: '',
            feedback: '',
        });
    }

    render() {
        const docmapClassName = this.props.match.params.mapType === DOCMAP ? 'active-wordmap-toggler' : '';
        const wordmapClassName = this.props.match.params.mapType === WORDMAP ? 'active-wordmap-toggler' : '';
        const searchClassName = this.props.match.params.mapType ? '' : 'active-wordmap-toggler';
        const docmapTooltip = 'Explore the document collection visually classified by topics.';
        const wordmapTooltip = 'Explore knowledge area with a visual representation of terms, documents, and relations between them.';
        return (
            <header className="top-header navbar border-bottom d-flex justify-content-lg-between justify-content-around">
                <div className="d-flex justify-content-between align-items-center">
                    {!configs.PARAMETERS.HIDE_LOGO &&
                        <a href="https://www.silkdata.ai/main"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <img className="logo" src={logo} alt="Silk Data"/>
                        </a>
                    }
                    <span className="ml-4 logo-text" style={{ maxWidth: 240 }}>Revolutionary way of representing knowledge</span>
                </div>

                <div>
                    {!configs.PARAMETERS.HIDE_SEARCH &&
                        <button
                            className={`btn btn-link wordmap-toggler ${searchClassName}`}
                            onClick={() => {
                                history.push(`${ROUTER_PREFIX}/search/${buildSearchUrl(this.props.searchValue,
                                    this.props.selectedTag && this.props.selectedTag.id,
                                    this.props.selectedDocument && this.props.selectedDocument.id,
                                    this.props.pageFrom,
                                    this.props.pageTo,
                                    this.props.testMode)}`);
                            }}
                            title={wordmapTooltip}
                        >
                            Search
                        </button>
                    }
                    {
                        !configs.PARAMETERS.HIDE_WORDMAP &&
                        <button
                            className={`btn btn-link wordmap-toggler ${wordmapClassName}`}
                            onClick={() => {
                                history.push(`${ROUTER_PREFIX}/map/${WORDMAP}`);
                            }}
                            title={wordmapTooltip}
                        >
                            Semantic map
                        </button>
                    }
                    {
                        !configs.PARAMETERS.HIDE_DOCMAP &&
                        <button
                            className={`btn btn-link wordmap-toggler ${docmapClassName}`}
                            onClick={() => {
                                history.push(`${ROUTER_PREFIX}/map/${DOCMAP}`);
                            }}
                            title={docmapTooltip}
                        >
                            Document map
                        </button>
                    }
                </div>

                <div className="top-header-about d-flex justify-content-between">
                    {!configs.PARAMETERS.HIDE_TUTORIAL &&
                        <a href="https://www.silkdata.ai/semantic-search-intro/"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <button className="mr-3 btn btn-link about-button tutorial-button">
                                Tutorial
                            </button>
                        </a>
                    }
                    {
                        !this.props.isMobile ? (
                            <>
                                {!configs.PARAMETERS.HIDE_ABOUT &&
                                    <button
                                        type="button"
                                        className="mr-3 btn btn-link about-button"
                                        onClick={() => this.setState({aboutIsOpen: true})}
                                    >
                                        About
                                    </button>
                                }
                                {!configs.PARAMETERS.HIDE_SEND_FEEDBACK &&
                                    <button
                                        type="button"
                                        className="feedback"
                                        onClick={() => this.setState({feedbackIsOpen: true})}
                                    >
                                        Send feedback
                                    </button>
                                }
                            </>) : null
                    }
                </div>

                <Modal
                    isOpen={this.state.aboutIsOpen}
                    onRequestClose={() => this.setState({ aboutIsOpen: false })}
                    style={this.aboutStyles}
                    contentLabel="About"
                >
                    <h1 className="d-flex justify-content-center uppercase">About</h1>
                    <div dangerouslySetInnerHTML={{ __html: config.about }} />
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary ok-button" onClick={() => this.setState({ aboutIsOpen: false })}>Close</button>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.feedbackIsOpen}
                    onRequestClose={() => this.setState({ feedbackIsOpen: false })}
                    style={this.feedbackStyles}
                    contentLabel="Feedback"
                >
                    <form className="d-flex flex-column justify-content-around h-100"
                          onSubmit={(event) => {
                              event.preventDefault();
                              this.sendFeedback();
                        }}>
                        <h1 className="d-flex justify-content-center uppercase">Send feedback</h1>
                        <div className="d-flex justify-content-around">
                            <img
                                src={happyIcon}
                                alt="Happy"
                                onClick={() => this.setState({ feedbackEmotion: 'happy' })}
                                className={this.state.feedbackEmotion === 'happy' ? 'img-border' : ''}
                            />
                            <img
                                src={dontCareIcon}
                                alt="Don't care"
                                onClick={() => this.setState({ feedbackEmotion: 'dontCare' })}
                                className={this.state.feedbackEmotion === 'dontCare' ? 'img-border' : ''}
                            />
                            <img
                                src={angryIcon}
                                alt="Angry"
                                onClick={() => this.setState({ feedbackEmotion: 'angry' })}
                                className={this.state.feedbackEmotion === 'angry' ? 'img-border' : ''}
                            />
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            aria-label="Name"
                            required
                            value={this.state.feedbackName}
                            onChange={event => this.setState({ feedbackName: event.target.value })}
                        />
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            // required
                            value={this.state.feedbackEmail}
                            onChange={event => this.setState({ feedbackEmail: event.target.value})}
                        />
                        <div>
                            <textarea
                                name="feedback"
                                id="feedback"
                                placeholder="Feedback..."
                                className="form-control w-100"
                                required
                                value={this.state.feedback}
                                onChange={event => this.setState({ feedback: event.target.value})}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary ok-button"
                                type="submit"
                                >Send</button>
                        </div>
                    </form>
                </Modal>

                <Modal
                    isOpen={this.state.successIsOpen}
                    onRequestClose={() => this.setState({ successIsOpen: false })}
                    style={this.successStyles}
                    contentLabel="Success"
                >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h1>Success</h1>
                        <p>Feedback has been successfully sent. Thank you!</p>
                        <button
                            type="button"
                            className="btn btn-primary ok-button"
                            onClick={() => this.setState({ successIsOpen: false })}
                        >
                            Got it!
                        </button>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.errorIsOpen}
                    onRequestClose={() => this.setState({ errorIsOpen: false })}
                    style={this.successStyles}
                    contentLabel="Success"
                >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h1>Error</h1>
                        <p>Ooops... Something went wrong. Try again later.</p>
                        <button
                            type="button"
                            className="btn btn-primary ok-button"
                            onClick={() => this.setState({ errorIsOpen: false })}
                        >
                            Got it!
                        </button>
                    </div>
                </Modal>
            </header>
        );
    }
}

Header.propTypes = {
    isMobile: PropTypes.bool
};

const mapStateToProps = state => ({
    searchValue: state.search.searchValue,
    selectedTag: state.search.selectedTag,
    selectedDocument: state.search.selectedDocument,
    pageFrom: state.search.pageFrom,
    pageTo: state.search.pageTo,
    testMode: state.search.testMode
});

export default connect(mapStateToProps)(Header);
