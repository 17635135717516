import {
    SET_TOPICS,
    GO_HOME,
    SET_TOP_ARTICLES,
    SET_TOP_WORDS,
    SET_ZOOM,
    SET_HIGHLIGHTED_WORDS,
    ADD_HIGHLIGHTED_WORD,
    REMOVE_HIGHLIGHTED_WORD,
    ADD_COLOR,
    REMOVE_COLOR,
    REMOVE_ALL_COLORS,
    TOGGLE_CENTERS_VISIBILITY,
    PAN_TO,
    CLEAR_HIGHLIGHTED_WORDS,
    TOGGLE_FILTERS_SIDEBAR_VISIBILITY,
    TOGGLE_TOPICS_SIDEBAR_VISIBILITY,
    SET_SELECTION_POSITION,
    SET_SEARCH_RESULTS,
    SET_SEARCH_VALUE,
    SET_SELECTED_TAG,
    SET_SELECTED_DOCUMENT,
    GENERATE_COLOR_MAP,
    CHANGE_PAGINATION,
    SHOW_LOADING,
    PUSH_TO_PREVIOUS_SEARCH,
    SHOW_ERROR,
    TOGGLE_TEST_MODE, BLOCK_UI_COORDS_REQUEST
} from './actionTypes';

import semanticMapApi from '../api/semanticMap';
import axios from "axios";

import configs from '../configs';
const { SEARCH_SERVER_URL } = configs;
const { SEARCH, ERROR_MSG } = configs.CONSTANTS;

export const setHighlightedWords = (highlightedWords, mapType) => ({
    type: SET_HIGHLIGHTED_WORDS,
    payload: { highlightedWords, mapType }
});

export const addHighlightedWord = (word, mapType) => ({
    type: ADD_HIGHLIGHTED_WORD,
    payload: { word, mapType }
});

export const removeHighlightedWord = (index, mapType) => ({
    type: REMOVE_HIGHLIGHTED_WORD,
    payload: { index, mapType }
});

export const clearHighlightedWords = (mapType) => ({
    type: CLEAR_HIGHLIGHTED_WORDS,
    payload: { mapType }
});

export const addColor = (color, mapType) => ({
    type: ADD_COLOR,
    payload: { color, mapType }
});

export const removeColor = (color, mapType) => ({
    type: REMOVE_COLOR,
    payload: { color, mapType }
});

export const removeAllColors = (mapType) => ({
    type: REMOVE_ALL_COLORS,
    payload: { mapType }
});


export const setZoom = (zoom, mapType) => ({
    type: SET_ZOOM,
    payload: { zoom, mapType }
});

export const setSelectionPosition = (selectionPosition, mapType) => ({
    type: SET_SELECTION_POSITION,
    payload: { selectionPosition, mapType }
});

export const goHome = home => ({
    type: GO_HOME,
    payload: { home }
});

export const panTo = topic => ({
    type: PAN_TO,
    payload: { topic }
});

export const setSearchResults = searchResults => ({
    type: SET_SEARCH_RESULTS,
    payload: searchResults
});

export const setSearchValue = searchValue => ({
    type: SET_SEARCH_VALUE,
    payload: { searchValue }
});

export const setSelectedTag = selectedTag => ({
    type: SET_SELECTED_TAG,
    payload: { selectedTag }
});

export const setSelectedDocument = selectedDocument => ({
    type: SET_SELECTED_DOCUMENT,
    payload: { selectedDocument }
});

export const changePagination = (pageFrom, pageTo) => ({
    type: CHANGE_PAGINATION,
    payload: { pageFrom, pageTo }
});

////////////////
// Visibility //
////////////////

export const toggleCentersVisibility = isVisible => ({
    type: TOGGLE_CENTERS_VISIBILITY,
    payload: { isVisible }
});

export const toggleFiltersSidebarVisibility = isVisible => ({
    type: TOGGLE_FILTERS_SIDEBAR_VISIBILITY,
    payload: { isVisible }
});

export const toggleTopicsSidebarVisibility = isVisible => ({
    type: TOGGLE_TOPICS_SIDEBAR_VISIBILITY,
    payload: { isVisible }
});

export const showError = (errMsg=ERROR_MSG) => ({
    type: SHOW_ERROR,
    payload: { error: errMsg }
});

export const showLoading = (show) => ({
    type: SHOW_LOADING,
    payload: { show }
});

export const setTestMode = (isTest) => ({
    type: TOGGLE_TEST_MODE,
    payload: { isTest }
});

///////////////
// UI Blocks //
//////////////

export const blockCoordsRequest = (isBlocked, word) => ({
    type: BLOCK_UI_COORDS_REQUEST,
    payload: { isBlocked, word }
});

//////////////////
// API requests //
//////////////////

// TODO Replace all dispatches with corresponding dispatches with function calls

export const getCoords = (mapType, word, availableColors) => async dispatch => {
    dispatch(blockCoordsRequest(true, word));

    try {
        const response = await semanticMapApi.get(`${mapType}/entity/coords`, {params: {entity: word}});
        const coords = response.data;
        if (coords === undefined || coords === null || response.status !== 200) {
            dispatch(showError(ERROR_MSG));
            return;
        }
        dispatch(addHighlightedWord({keyword: word, color: availableColors[0], coords}, mapType));
        dispatch(blockCoordsRequest(false, word));
    } catch (e) {
        dispatch(showError(ERROR_MSG));
        dispatch(blockCoordsRequest(false, word));
    }
};

export const getTopics = (mapType) => async dispatch => {
    if (mapType === SEARCH) return; // do not request topics for this map

    try {
        const response = await semanticMapApi.get(`${mapType}/topics`);
        const topics = response.data;
        if (topics === undefined || topics === null || response.status !== 200) {
            dispatch(showError(ERROR_MSG));
            return;
        }
        dispatch({
            type: SET_TOPICS,
            payload: { topics, mapType }
        });
    } catch (e) {
        dispatch(showError(ERROR_MSG));
    }
};

export const rect = (mapType, params) => async dispatch => {
    if (mapType === SEARCH) return; // no rect queries for this map

    try {
        const response = await semanticMapApi.get(`${mapType}/rect`, {params});
        const {articles, words} = response.data;

        if (articles === undefined || articles === null || words === undefined || words === null ||
            response.status !== 200) {
            dispatch(showError(ERROR_MSG));
            return;
        }

        dispatch({
            type: SET_TOP_ARTICLES,
            payload: {topArticles: articles, mapType}
        });
        dispatch({
            type: SET_TOP_WORDS,
            payload: {topWords: words, mapType}
        });
    } catch (e) {
        dispatch(showError(ERROR_MSG));
    }
};

export const search = (params, selectedTagId, selectedDocId) => async (dispatch, getState) => {
    dispatch(showLoading(true));
    try {
        if (selectedDocId) {
            const selectedDocument = (await axios.get(`${SEARCH_SERVER_URL}/document/${selectedDocId}`)).data;
            dispatch(setSelectedDocument(selectedDocument));
        }

        if (!params.query && !params.doc && !params.tag) {
            dispatch(showLoading(false));
            return;
        }

        const response = await axios.get(`${SEARCH_SERVER_URL}/search`, { params });
        const data = response.data;
        const state = getState();

        dispatch(showLoading(false));

        if (data === undefined || data === null || response.status !== 200) {
            dispatch(showError(ERROR_MSG));
            return;
        }

        dispatch({
            type: GENERATE_COLOR_MAP,
            payload: { tags: data.tags, prevColorMap: state.search.colorMap }
        });
        dispatch({
            type: SET_SEARCH_RESULTS,
            payload: { searchResults: data.documents, tags: data.tags, totalHits: data.totalHits }
        });
        if (params.query && params.query.length && !state.search.previousSearch.includes(params.query)) {
            dispatch({
                type: PUSH_TO_PREVIOUS_SEARCH,
                payload: { query: params.query }
            });
        }
        if (selectedTagId) {
            const selectedTag = data.tags.filter(tag => String(tag.id) === selectedTagId)[0];
            dispatch(setSelectedTag(selectedTag));
            getCoords(SEARCH, selectedTag.name, [getState().search.colorMap[selectedTagId]])(dispatch);
        }
    } catch (e) {
        dispatch(showLoading(false));
        dispatch(showError(ERROR_MSG));
    }

};
