import React from 'react';
import PropTypes from 'prop-types';
import OpenSeadragon from 'openseadragon';
import Overlay from './openseadragon-canvas-overlay';
import {connect} from 'react-redux';

import {
    getTopics,
    goHome,
    panTo, rect,
    setHighlightedWords,
    setSelectionPosition,
    setZoom
} from '../../../actions';

import configs from '../../../configs';
const { SELECTION_WIDTH, SELECTION_HEIGHT,
        WORDMAP_IMAGE_WIDTH, WORDMAP_IMAGE_HEIGHT,
        DOCMAP_IMAGE_HEIGHT, DOCMAP_IMAGE_WIDTH } = configs.PARAMETERS;
const { WORDMAP, DOCMAP, SEARCH } = configs.CONSTANTS;


class SemanticMap extends React.Component {
    canvasOverlay = null;
    destroy = false; // true on unmount

    get imageWidth() {
        return this.props.mapType === WORDMAP ? WORDMAP_IMAGE_WIDTH : DOCMAP_IMAGE_WIDTH;
    }

    get imageHeight() {
        return this.props.mapType === WORDMAP ? WORDMAP_IMAGE_HEIGHT : DOCMAP_IMAGE_HEIGHT;
    }

    get mapType() {
        switch (this.props.mapType) {
            case DOCMAP:
            case SEARCH:
                return DOCMAP;
            case WORDMAP:
            default:
                return WORDMAP;
        }
    }

    componentDidMount() {
        this.initializeOpenseadragonViewer();
        if (!configs.PARAMETERS.HIDE_TOPICS) {
            this.retrieveTopics();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.topics.length && this.props.topics !== prevProps.topics) {
            this.props.topics.forEach((center, idx) => this.addClusterCenter(this.viewer, center, idx));
        }

        if (this.props.atHome) {
            const minZoom = this.viewer.viewport.getMinZoom();
            this.viewer.viewport.zoomTo(minZoom, null, false);
            this.props.setZoom(minZoom, this.props.mapType);
            this.viewer.viewport.panTo(new OpenSeadragon.Point(0.5, 0.5), false);
            this.props.goHome(false);
        }

        if (this.props.topicToPan) {
            let { x, y, importance } = this.props.topicToPan;
            const pointToPan = this.viewer.viewport.imageToViewportCoordinates(parseFloat(x), parseFloat(y));
            importance = parseFloat(importance);

            // handle zero case separately to avoid float precision issues
            let newZoom = importance !== 0.0 ?
                this.viewer.viewport.getMinZoom() +((1 - importance) * (this.viewer.viewport.getMaxZoom() - this.viewer.viewport.getMinZoom())) :
                this.viewer.viewport.getMaxZoom();

            this.viewer.viewport.zoomTo(newZoom, null, false);
            this.viewer.viewport.panTo(new OpenSeadragon.Point(pointToPan.x, pointToPan.y), false);

            const selectionPosition = { x: pointToPan.x, y: pointToPan.y};
            this.viewer.updateOverlay(`selection-${this.props.mapType}`,
                                      new OpenSeadragon.Point(pointToPan.x, pointToPan.y),
                                      OpenSeadragon.Placement.CENTER);

            // show cluster centers with some delay
            setTimeout(() => {
                this.props.setZoom(newZoom, this.props.mapType);
                this.props.setSelectionPosition(selectionPosition, this.props.mapType);
            }, 50);

            this.retrieveKeywords(
                Math.round(pointToPan.x * this.imageWidth),
                Math.round(pointToPan.y * this.imageHeight));
            this.props.panTo(null);
        }

        const needRedraw = this.props.highlightedWords !== prevProps.highlightedWords || (this.props.zoom !== prevProps.zoom);

        if (needRedraw) {
            this.initializeCanvasOverlay();
        }
    }

    componentWillUnmount() {
        this.destroy = true
    }

    initializeOpenseadragonViewer() {
        this.viewer = OpenSeadragon({
            id: `openseadragon-${this.props.mapType}`,
            tileSources: this.getTileSourcesPath(),
            showNavigator: (window.innerWidth > 768) && (this.props.mapType !== SEARCH),
            showNavigationControl: false,
            zoomPerClick: 1,
            debugMode:  false
        });

        // remember zoom from the state
        const currentZoom = this.props.zoom;

        this.viewer.addHandler('canvas-click', (event) => this.handleCanvasClick(event));
        this.viewer.addHandler('canvas-scroll', () => this.handleCanvasScroll());
        this.viewer.addHandler('animation-finish', () => this.handleAnimationEnd());

        this.retrieveKeywords(Math.round(this.props.selectionPosition.x * this.imageWidth), Math.round(this.props.selectionPosition.y * this.imageHeight));

        if (this.props.topics.length) {
            this.props.topics.forEach((center, idx) => this.addClusterCenter(this.viewer, center, idx));
        }

        const tileLoadedHandler = () => {
            // give some timeout before reverting to currentZoom for smoother zooming
            setTimeout(() => this.props.setZoom(currentZoom, this.props.mapType), 50);
            if (this.destroy) return;
            this.viewer.removeHandler('tile-loaded', tileLoadedHandler);
            this.viewer.viewport.zoomTo(currentZoom, this.props.selectionPosition, false);
            this.viewer.viewport.panTo(this.props.selectionPosition, false);
            this.initializeCanvasOverlay();
            this.viewer.addOverlay(`selection-${this.props.mapType}`,
                new OpenSeadragon.Point(this.props.selectionPosition.x, this.props.selectionPosition.y),
                OpenSeadragon.Placement.CENTER);
        };

        this.viewer.viewport.zoomTo(this.props.zoom, this.props.selectionPosition, true);
        this.viewer.addHandler('tile-loaded', tileLoadedHandler);

        // set zoom to 1, but it will be returned to currentZoom when the image is fully loaded
        this.props.setZoom(1, this.props.mapType);
    }

    initializeCanvasOverlay() {
        const onRedraw = () => {
            this.props.highlightedWords.forEach(term => {
                this.canvasOverlay.context2d().fillStyle = term.color;
                term.coords.map(({x, y}) => this.viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(x, y))).forEach(({x, y}) => {
                    this.canvasOverlay.context2d().fillRect(x, y, 0.001/Math.sqrt(this.props.zoom), 0.001/Math.sqrt(this.props.zoom));
                });
            })};

        if (!this.canvasOverlay) {
            this.canvasOverlay = new Overlay(this.viewer, {
                onRedraw,
                clearBeforeRedraw: true
            });
        } else {
            this.canvasOverlay.onRedraw = onRedraw;
        }

        // I HAVE NO IDEA WHY WE NEED TO DO IT TWICE!!
        try {
            for (let i = 0; i < 2; i++) {
                this.canvasOverlay.resize();
                this.canvasOverlay._updateCanvas();
                this.canvasOverlay.onRedraw();
            }
        } catch (e) {
            // this is ok, canvas overlay has not been initialized yet
        }
    }

    getTileSourcesPath() {
        return `${process.env.REACT_APP_PREFIX}/assets/${this.mapType}.dzi`;
    }

    retrieveTopics() {
        this.props.getTopics(this.props.mapType);
    }

    // Idk the exact reason, but sometimes Openseadragon does not return correct bounds :(
    getBounds() {
        const {x, y} = this.props.selectionPosition;
        const width  = 1.0 / this.props.zoom;
        const height = width * this.viewer.viewport.getAspectRatio();

        return new OpenSeadragon.Rect(x - (width / 2.0), y - (height / 2.0), width, height);
    }

    changeSelectionPosition(x, y) {
        this.props.setSelectionPosition({ x, y }, this.props.mapType);
    }

    addClusterCenter(viewer, center, idx) {
        if (!center.x || !center.y) return;

        const centerHighlight = `center-${idx}`;
        const relativeCoordsCenter = {x: parseFloat(center.x)/this.imageWidth, y: parseFloat(center.y)/this.imageHeight};
        const centerPosition = new OpenSeadragon.Point(relativeCoordsCenter.x, relativeCoordsCenter.y);
        viewer.addOverlay(centerHighlight, centerPosition, OpenSeadragon.Placement.CENTER);
    }

    handleCanvasClick(event) {
        if (!event.quick) {
            return; // it's actually a drag event
        }
        const viewportPoint = this.viewer.viewport.pointFromPixel(event.position);
        const imagePoint = this.viewer.viewport.viewportToImageCoordinates(viewportPoint.x, viewportPoint.y);
        const x = Math.round(imagePoint.x);
        const y = Math.round(imagePoint.y);
        this.retrieveKeywords(x, y);
        this.changeSelectionPosition(x/this.imageWidth, y/this.imageHeight);
    }

    handleCanvasScroll() {
        const currentZoom = this.viewer.viewport.getZoom(true);
        this.props.setZoom(currentZoom, this.props.mapType);
    }

    handleAnimationEnd() {
        this.retrieveKeywords(
            Math.round(this.props.selectionPosition.x * this.imageWidth),
            Math.round(this.props.selectionPosition.y * this.imageHeight)
        )
    }

    retrieveKeywords(x, y) {
        if (this.viewer.container.clientWidth === 0 || this.viewer.container.clientWidth === 0) {
            return; // viewer is not ready yet
        }

        const bounds = this.viewer.viewport.getBounds(true);

        const scaleFactor = {
            x: SELECTION_WIDTH / this.viewer.container.clientWidth,
            y: SELECTION_HEIGHT / this.viewer.container.clientHeight
        };

        const absoluteSelectionWidth = scaleFactor.x * bounds.width * this.imageWidth;
        const absoluteSelectionHeight = scaleFactor.y * bounds.height * this.imageHeight;
        const halfSelectionWidth = Math.round(absoluteSelectionWidth/2);
        const halfSelectionHeight = Math.round(absoluteSelectionHeight/2);

        if (!isFinite(x) || !isFinite(y) || !isFinite(absoluteSelectionWidth) || !isFinite(absoluteSelectionHeight)) {
            return;
        }

        const maxKeywordsNum = this.mapType === WORDMAP ? 50 : 5;
        const params =  {
            xmin: x-halfSelectionWidth,
            xmax: x+halfSelectionWidth,
            ymin: y-halfSelectionHeight,
            ymax: y+halfSelectionHeight,
            max_keywords_num: maxKeywordsNum
        };
        this.props.rect(this.props.mapType, params);

        const viewportCoordinates = this.viewer.viewport.imageToViewportCoordinates(x, y);
        this.viewer.updateOverlay(`selection-${this.props.mapType}`,
            new OpenSeadragon.Point(viewportCoordinates.x, viewportCoordinates.y),
            OpenSeadragon.Placement.CENTER);
    }

    isCenterHidden(center, zoom) {
        if (!this.viewer) {
            return true;
        }
        const minZoom = this.viewer.viewport.getMinZoom();
        const maxZoom = this.viewer.viewport.getMaxZoom();
        const importance = parseFloat(center.importance);

        if (importance >= 1) {
            return false; // important topics are always visible
        }

        if (zoom <= 1) {
            return true; // show only important topics when zoom out
        } else if (importance >= 0.8) {
            return false; // show less important topic on little zoom as well
        }

        if (zoom > 0.8 * maxZoom) {
            return false; // show all topics when user has heavily zoomed in
        }

        const [minImportance, maxImportance] = [0, 1];
        
        return  (1 - (zoom - minZoom) / (maxZoom - minZoom)) - (importance - minImportance) / (maxImportance - minImportance) > 0.3;
    }

    generateStyle(center, noDisplay) {
        const classes = ['center'];
        const isHidden = !this.props.centersVisible || this.isCenterHidden(center, this.props.zoom ? this.props.zoom : 0);
        const isImportant = parseFloat(center.importance) > 0.98;
        if (isHidden) {
            classes.push('hidden');
        }
        if (noDisplay || !center.x || !center.y) {
            classes.push('d-none');
        }
        if (isImportant) {
            classes.push('center-important');
        }

        return classes.join(' ');
    }

    render() {
        return (
            <div id="semantic-map">
                <div id={`openseadragon-${this.props.mapType}`} />
                <div id={`selection-${this.props.mapType}`} />
                {this.props.topics.map((center, idx) => (
                        <div
                            id={`center-${idx}`}
                            key={idx}
                            className={this.generateStyle(center, false)}
                        />
                    ))
                }

                <style dangerouslySetInnerHTML={{
                    __html: this.props.topics.map((center, idx) => {
                        return [
                            `#center-${idx}:after {`,
                            `content: "${center.name}";`,
                            '}'
                        ].join('\n')
                    }).join('\n')
                }}>
                </style>
            </div>
        )
    }
}

SemanticMap.propTypes = {
    mapType: PropTypes.oneOf([WORDMAP, DOCMAP, SEARCH]),
    topics: PropTypes.arrayOf(PropTypes.exact({ x: PropTypes.number, y: PropTypes.number, importance: PropTypes.number, name: PropTypes.string })),
    highlightedWords: PropTypes.arrayOf(
        PropTypes.exact({
            keyword: PropTypes.string,
            color: PropTypes.string,
            coords: PropTypes.arrayOf(PropTypes.exact({ x: PropTypes.number, y: PropTypes.number }))}
        )
    ),
    atHome: PropTypes.bool,
    centersVisible: PropTypes.bool,
    topicToPan: PropTypes.exact({ x: PropTypes.number, y: PropTypes.number, importance: PropTypes.number, name: PropTypes.string }),
    zoom: PropTypes.number,
    selectionPosition: PropTypes.exact({ x: PropTypes.number, y: PropTypes.number }),

    setHighlightedWords: PropTypes.func.isRequired,
    goHome: PropTypes.func.isRequired,
    panTo: PropTypes.func.isRequired,
    setZoom: PropTypes.func.isRequired,
    setSelectionPosition: PropTypes.func.isRequired,
    getTopics: PropTypes.func.isRequired,
    rect: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    topics: state[ownProps.mapType].topics,
    highlightedWords: state[ownProps.mapType].highlightedWords,

    atHome: state[ownProps.mapType].atHome,
    centersVisible: state.visibility.centersVisible,
    topicToPan: state[ownProps.mapType].panTo,
    zoom: state[ownProps.mapType].zoom,
    selectionPosition: state[ownProps.mapType].selectionPosition
});



export default connect(mapStateToProps, { setHighlightedWords,
                                          goHome,
                                          panTo,
                                          setZoom,
                                          setSelectionPosition,
                                          getTopics,
                                          rect })(SemanticMap);
