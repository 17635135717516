import 'react-app-polyfill/ie9'; // ensures that at least window.fetch is present
import 'react-app-polyfill/stable'; // ensures that at least window.fetch is present
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import {Provider} from 'react-redux';
import TagManager from 'react-gtm-module';
import store from './util/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://e302611c255a4c35bdb50e1feb11bbf0@sentry.silkcodeapps.de/17"});

    if (process.env.REACT_APP_GA_TAG) {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GA_TAG
        };
        TagManager.initialize(tagManagerArgs);
    }
}

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
