import React from 'react';
// import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addColor, addHighlightedWord, getCoords, removeColor, removeHighlightedWord} from '../../../../../../actions';

import Chip from '../../../../../shared/chip';

import './styles.css';

import configs from '../../../../../../configs';
const { COLORS } = configs;

const debounce = function(f, ms) {
    let isCooldown = false;
    return function() {
        if (isCooldown) return;
        f.apply(this, arguments);
        isCooldown = true;
        setTimeout(() => isCooldown = false, ms);
    };
};

class KeywordDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeywordClick = debounce(this.handleKeywordClick.bind(this), 400).bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.isSelected !== nextProps.isSelected;
    }

    handleKeywordClick() {
        const word = this.props.word;
        if (this.props.blockUiCoordsRequest[word]) return;

        if (this.props.highlightedWords.map(word => word.keyword).includes(word)) {
            if (!this.props.hasClearButton) {
                this.removeHighlightedWord(word);
            }
            return;
        }

        const availableColors = COLORS.filter(color => !this.props.usedColors.includes(color));
        if (!availableColors.length) {
            return;
        }
        this.props.addColor(availableColors[0], this.props.mapType);
        this.props.getCoords(this.props.mapType, word, availableColors);
    }

    removeHighlightedWord(word) {
        const index = this.props.highlightedWords.map(word => word.keyword).indexOf(word);
        if (index > -1) {
            this.props.removeHighlightedWord(index, this.props.mapType);
            this.props.removeColor((this.props.highlightedWords)[index].color, this.props.mapType);
        }
    }

    render() {
        let color = null;
        // It's actually an array from just one element
        if (this.props.highlightedWord.length) {
            color = this.props.highlightedWord[0].color;
        }
        return (
            <Chip
                text={this.props.word}
                color={color}
                hasClearButton={this.props.hasClearButton}
                handleClick={() => this.handleKeywordClick()}
                handleClearClick={() => this.removeHighlightedWord(this.props.word)}
            />
        );
    }
}

// KeywordDetails.propTypes = {
//     word: PropTypes.string.isRequired,
//     highlightedWord: PropTypes.arrayOf(
//         PropTypes.shape(
//             { keyword: PropTypes.string,
//                 color: PropTypes.string,
//                 coords: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number}))}
//         )
//     ), // array of just one element
//     highlightedWords: PropTypes.arrayOf(
//         PropTypes.shape(
//             { keyword: PropTypes.string,
//                 color: PropTypes.string,
//                 coords: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number}))}
//         )
//     ),
//     hasClearButton: PropTypes.bool,
//     usedColors: PropTypes.arrayOf(PropTypes.string).isRequired,
//     isSelected: PropTypes.bool.isRequired,
//     mapType: PropTypes.oneOf([WORDMAP, DOCMAP])
// };

const mapStateToProps = (state, ownProps) => ({
    highlightedWords: state[ownProps.mapType].highlightedWords,
    usedColors: state[ownProps.mapType].usedColors,
    blockUiCoordsRequest: state.uiBlock.blockUiCoordsRequest
});


export default connect(mapStateToProps, { addHighlightedWord, removeHighlightedWord, addColor, removeColor, getCoords })(KeywordDetails);
