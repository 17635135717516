import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Route} from 'react-router-dom';
import KeywordSearch from './keyword-search';
import TopicsSearch from './topics-search';

import { toggleFiltersSidebarVisibility } from '../../actions';

import './styles.css';

import configs from '../../configs';
const { ROUTE_MAP_TYPE } = configs;


class FiltersSidebar extends React.Component {
    render() {
        // might be useful in future when in mobile view the panel won't be completely hidden
        const notVisible = !this.props.filtersSidebarVisible || this.props.isMobile;

        if (this.props.isMobile) {
            return <span />;
        }

        return (
            <>
                <nav className="filters-sidebar" style={notVisible ? { width: 0, minWidth: 0 } : {}}>
                    <div className="d-flex justify-content-lg-between flex-lg-row flex-column-reverse">
                        <h3 className="m-2 uppercase">Map explorer</h3>
                        <h3
                            onClick={() => this.props.toggleFiltersSidebarVisibility(false)}
                            className="collapse-button mr-3 mt-2"
                        >☰</h3>
                    </div>
                    <hr className="divider" />
                    <Route path={ROUTE_MAP_TYPE} render={({ match }) => <KeywordSearch match={match} />}/>
                    <hr className="divider" />
                    {
                        !configs.PARAMETERS.HIDE_TOPICS &&
                        <Route path={ROUTE_MAP_TYPE} render={({match}) => <TopicsSearch match={match}/>}/>
                    }
                </nav>
                { notVisible ? (
                    <div className="collapsed-toolbar">
                        <h3
                            onClick={() => this.props.toggleFiltersSidebarVisibility(true)}
                            className="collapse-button mr-1 mb-2"
                        >☰</h3>
                        <h3 className="uppercase">Map explorer</h3>
                    </div>
                ) : null}
            </>
        );
    }
}

FiltersSidebar.propTypes = {
    centersVisible: PropTypes.bool,
    filtersSidebarVisible: PropTypes.bool,
    toggleFiltersSidebarVisibility: PropTypes.func,
    isMobile: PropTypes.bool
};

const mapStateToProps = (state) => ({
    centersVisible: state.visibility.centersVisible,
    filtersSidebarVisible: state.visibility.filtersSidebarVisible
});

export default connect(mapStateToProps, { toggleFiltersSidebarVisibility })(FiltersSidebar);
