import React from 'react';
import PropTypes from 'prop-types';

import magnifier from '../../../../../../images/magnifier.svg';

import './styles.css';

const HEIGHT_LIMIT = 70; // in px

class GrowingInput extends React.Component {
    state = {
        searchTerm: this.props.query,
        scrollHeight: 30
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current.style.height = '30px';
        this.updateTextareaHeight();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.query !== prevProps.query) {
            this.setState({ searchTerm: this.props.query }, () => this.updateTextareaHeight());
        }
    }

    updateTextareaHeight() {
        const textarea = this.inputRef.current;
        textarea.style.height = "1px"; // reset height to get true height of the text
        textarea.style.height = Math.min(textarea.scrollHeight + 2, HEIGHT_LIMIT) + "px";
        this.setState({ scrollHeight: this.inputRef.current.scrollHeight + 2 });
    }

    handleOnChange(event) {
        this.updateTextareaHeight();
        this.setState({ searchTerm: event.target.value });
        this.props.handleOnChange(event);
    }

    handleClearClick() {
        this.setState({ searchTerm: '' }, () => this.updateTextareaHeight());
        this.props.handleClearSearch();
    }

    handleOnKeyPress(event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            this.props.handleOnSubmit(event);
        }
    }

    computeClearButtonStyles() {
        if (this.state.scrollHeight > HEIGHT_LIMIT) {
            return { fontSize: 22 };
        }

        if (this.state.scrollHeight >= HEIGHT_LIMIT / 2) {
            return { fontSize: 18 };
        }

        return { fontSize: 16 }
    }

    render() {
        const showClearButton = this.state.searchTerm.trim().length > 0;
        return (
            <form className="d-flex justify-content-center" onSubmit={event => this.props.handleOnSubmit(event)}>
                <div className="d-flex growing-input-container">
                    <img src={magnifier} alt="Search" className="growing-input-magnifier" />
                    <div className="d-flex" style={{ width: '90%' }}>
                    <textarea
                        name="growing-input"
                        id="growing-input"
                        ref={this.inputRef}
                        value={this.state.searchTerm}
                        onChange={(event) => this.handleOnChange(event)}
                        onKeyDown={event => this.handleOnKeyPress(event)}
                        style={showClearButton ? { flexBasis: '99%'} : {flexBasis: '100%'}}
                    />
                    {
                        showClearButton ? (
                            <span
                                className="growing-input-clear"
                                onClick={() => this.handleClearClick()}
                                style={this.computeClearButtonStyles()}
                            >
                            &times;
                        </span>
                        ) : null

                    }
                    </div>
                    <button className="growing-input-button" style={{ width: '10%', minWidth: 65 }}>
                        Search
                    </button>
                </div>
            </form>
        );
    }
}

GrowingInput.propTypes = {
    handleOnSubmit: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired
};

export default GrowingInput;
