const emptyState = {
    previousSearch: []
};
Object.freeze(emptyState);

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('__react__app__state');
        if (serializedState === null) {
            return emptyState;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return emptyState;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('__react__app__state', serializedState);
    } catch (err) {
        console.error(err);
    }
};
