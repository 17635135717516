import React from 'react';
import { connect } from 'react-redux';
import ItemsContainer from '../../../shared/items-container';
import Chip from '../../../shared/chip';
import {
    addHighlightedWord,
    changePagination,
    getCoords,
    removeHighlightedWord,
    search,
    setSelectedTag
} from '../../../../actions';
import './styles.css';

import configs from '../../../../configs';
const { SEARCH } = configs.CONSTANTS;
const { DOCUMENTS_PER_PAGE } = configs.PARAMETERS;

class TagPanel extends React.Component {
    // TODO Make an action
    handleClick(tag) {
        if (!this.props.selectedTag || this.props.selectedTag.id !== tag.id) {
            this.props.changePagination(0, DOCUMENTS_PER_PAGE - 1);
            this.props.setSelectedTag(tag);
        } else {
            this.props.setSelectedTag(null);
            this.props.removeHighlightedWord(0, SEARCH);
        }
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }

        return (
            <div className="tag-panel">
                <h3 className="tag-panel-title">Which context are you interested in?</h3>
                <ItemsContainer styles={{ height: 95, background: 'rgba(230, 230, 230, 0.3)', borderRadius: 10 }}>
                    {this.props.tags.map(el =>
                        <Chip
                            key={el.id}
                            text={el.name}
                            handleClick={() => this.handleClick(el)}
                            color={(this.props.selectedTag && String(this.props.selectedTag.id) === String(el.id)) ? this.props.colorMap[el.id] : null}
                            handleClearClick={() => {}}
                        />)}
                </ItemsContainer>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    usedColors: state.search.usedColors,
    highlightedWords: state.search.highlightedWords,
    colorMap: state.search.colorMap,
    searchValue: state.search.searchValue,
    selectedTag: state.search.selectedTag,
    selectedDocument: state.search.selectedDocument,
    pageFrom: state.search.pageFrom,
    pageTo: state.search.pageTo,
    testMode: state.search.testMode
});

export default connect(mapStateToProps, {
    search,
    setSelectedTag,
    getCoords,
    addHighlightedWord,
    changePagination, removeHighlightedWord })(TagPanel);
