import {
    SET_HIGHLIGHTED_WORDS,
    ADD_HIGHLIGHTED_WORD,
    REMOVE_HIGHLIGHTED_WORD,
    CLEAR_HIGHLIGHTED_WORDS,
    SET_ZOOM,
    SET_SEARCH_RESULTS,
    SET_SEARCH_VALUE,
    SET_SELECTED_TAG,
    SET_SELECTED_DOCUMENT,
    GENERATE_COLOR_MAP,
    CHANGE_PAGINATION, SHOW_LOADING, PUSH_TO_PREVIOUS_SEARCH, TOGGLE_TEST_MODE
} from '../actions/actionTypes';

import { generateRandomColor } from '../util';
import { loadState } from '../util/localStorage';

import configs from '../configs';
const { COLORS } = configs;
const { SEARCH } = configs.CONSTANTS;
const { DOCUMENTS_PER_PAGE, SEARCH_HISTORY_CAPACITY } = configs.PARAMETERS;

const initialState = {
    topics: [],
    articles: [],
    words: [],
    usedColors: [],

    selectionPosition: { x: 0.5, y: 0.5 },

    highlightedWords: [],
    zoom: 1,
    searchResults: [],
    tags: [],

    searchValue: '',
    selectedTag: null,
    selectedDocument: null,
    colorMap: {},

    pageFrom: 0,
    pageTo: DOCUMENTS_PER_PAGE - 1,

    showLoading: false,
    previousSearch: loadState().previousSearch || [],

    testMode: false
};

export default (state=initialState, action) => {
    switch (action.type) {

        case SET_HIGHLIGHTED_WORDS:
            if (action.payload.mapType === SEARCH) {
                return {
                    ...state,
                    highlightedWords: action.payload.highlightedWords
                };
            }
            return state;

        case ADD_HIGHLIGHTED_WORD:
            const { word } = action.payload;
            if (action.payload.mapType === SEARCH) {
                return {
                    ...state,
                    highlightedWords: state.highlightedWords.concat([word])
                };
            }
            return state;

        case REMOVE_HIGHLIGHTED_WORD:
            const { index } = action.payload;
            if (action.payload.mapType === SEARCH) {
                return {
                    ...state,
                    highlightedWords: state.highlightedWords.filter((_, idx) => idx !== index)
                };
            }
            return state;

        case CLEAR_HIGHLIGHTED_WORDS:
            if (action.payload.mapType === SEARCH) {
                return {
                    ...state,
                    highlightedWords: []
                };
            }
            return state;

        case SET_ZOOM:
            const { zoom } = action.payload;
            if (action.payload.mapType === SEARCH) {
                return {
                    ...state,
                    zoom
                };
            }
            return state;

        case SET_SEARCH_RESULTS:
            const { searchResults, tags, totalHits } = action.payload;
            return {
                ...state,
                searchResults,
                tags,
                totalHits
            };

        case SET_SEARCH_VALUE:
            const { searchValue } = action.payload;
            return {
                ...state,
                searchValue
            };

        case SET_SELECTED_TAG:
            const { selectedTag } = action.payload;
            return {
                ...state,
                selectedTag
            };

        case SET_SELECTED_DOCUMENT:
            const { selectedDocument } = action.payload;
            return {
                ...state,
                selectedDocument
            };

        case GENERATE_COLOR_MAP:
            const { tags: allTags, prevColorMap } = action.payload;
            const usedColors = new Set();

            let selectedTagMap = {};
            if (state.selectedTag) {
                usedColors.add(prevColorMap[state.selectedTag.id]);
                selectedTagMap = {[state.selectedTag.id]: prevColorMap[state.selectedTag.id]};
            }

            const colorMap = allTags
                .map((tag, idx) => {
                    if (tag.id in prevColorMap) {
                        usedColors.add(prevColorMap[tag.id]);
                        return { [tag.id]: prevColorMap[tag.id] };
                    }

                    return {
                        [tag.id]: (idx < COLORS.length && !usedColors.has(COLORS[idx])) ? COLORS[idx] : generateRandomColor()
                    }
                })
                .reduce((prev, curr) => ({...prev, ...curr}), selectedTagMap);
            return {
                ...state,
                colorMap
            };

        case CHANGE_PAGINATION:
            const { pageFrom, pageTo } = action.payload;
            return {
                ...state,
                pageFrom,
                pageTo
            };

        case SHOW_LOADING:
            const { show } = action.payload;
            return {
                ...state,
                showLoading: show
            };

        case PUSH_TO_PREVIOUS_SEARCH:
            const { query } = action.payload;
            const previousSearch = [decodeURIComponent(query), ...state.previousSearch.slice(0, SEARCH_HISTORY_CAPACITY - 1)];
            return {
                ...state,
                previousSearch
            };

        case TOGGLE_TEST_MODE:
            const { isTest } = action.payload;
            return {
                ...state,
                testMode: isTest
            };

        default:
            return state;
    }
};
