function generateRandomColor(avoidDark=true) {
    const letters = '0123456789ABCDEF';
    const hexColor = Array.from({ length: 6}, () => letters[Math.floor(Math.random()*16)])
        .reduce((prev, curr) => (prev + curr), '#');
    if (avoidDark && checkDarkColor(hexColor)) return generateRandomColor(); // try once more if color is too dark
    return hexColor;
}

// see https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
function checkDarkColor(hexColor) {
    const rgbColor = parseInt(hexColor.substring(1), 16);
    const [r, g, b] = [(rgbColor >> 16) & 0xff, (rgbColor >> 8) & 0xff, (rgbColor >> 0) & 0xff];
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 60;
}

export { generateRandomColor };


function checkNoneValue(val) {
    return val !== null && val !== undefined;
}

function buildSearchUrl(query, tagId, docId, pageFrom, pageTo, test) {
    if (!query) {
        return '';
    }

    return [`?query=${encodeURIComponent(query)}`,
        checkNoneValue(tagId) ? `tag=${tagId}` : '',
        checkNoneValue(docId) ? `doc=${docId}` : '',
        `from=${pageFrom}`,
        `to=${pageTo}`,
        test ? `test=${test}` : '']
        .filter(el => el.length > 0)
        .join('&');
}

export { buildSearchUrl };
