import React from 'react';
// import PropTypes from 'prop-types';
import './styles.css';

class Chip extends React.PureComponent {
    render() {
        const styles = this.props.styles ? this.props.styles : {};
        return (
            <span
                style={ this.props.color ? { background: this.props.color, ...styles }  : styles}
                className="chip"
                onClick={this.props.handleClick}
                title={this.props.title}
            >
                {this.props.text}
                {this.props.color && this.props.hasClearButton ?  (
                    <span
                        className="ml-1 chip-clear"
                        onClick={this.props.handleClearClick}
                    >
                        &times;
                    </span>) : null}
            </span>
        );
    }
}

// Chip.propTypes = {
//     color: PropTypes.string,
//     text: PropTypes.string.isRequired,
//     hasClearButton: PropTypes.bool,
//     handleClick: PropTypes.func.isRequired,
//     handleClearClick: PropTypes.func.isRequired,
//     styles: PropTypes.object
// };

export default Chip;
