import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { v4 } from 'node-uuid';

import KeywordDetails from '../../topics-sidebar/components/keywords-container/components/keyword-details';
import ItemsContainer from '../../shared/items-container';
import SearchInput from '../../shared/search-input';

import {showError} from '../../../actions';

import infoIcon from '../../../images/infoIcon.svg';
import './styles.css';

import configs from '../../../configs';
const { BASE_URL } = configs;
const { WORDMAP, DOCMAP } = configs.CONSTANTS;


class KeywordSearch extends React.Component {
    state = {
        initialState: [],
        filteredKeywords: null,
        searchTermWordmap: '',
        searchTermDocmap: ''
    };

    get searchTerm() {
        return this.props.mapType === WORDMAP ? this.state.searchTermWordmap : this.state.searchTermDocmap;
    }

    componentDidMount() {
        this.getInitialState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mapType !== prevProps.mapType) {
            this.setState({ filteredKeywords: null });
            if (this.searchTerm.length === 0) {
                this.getInitialState();
            } else {
                this.search(this.searchTerm);
            }
        }
    }

    getInitialState() {
        axios.get(`${BASE_URL}/${this.props.mapType}/entity/topList`, {
            params: {
                max_result_len: 300
            }
        }).then(response => {
            if (response.status !== 200 || response.data === undefined || response.data === null) {
                this.props.showError();
            }
            this.setState({ initialState: response.data });
        }).catch(err => {
            this.props.showError();
        })
    }

    search(term) {
        this.updateSearchState(term);
        if (this.isSearchTermEmpty(term)) {
            this.setState({ filteredKeywords: null });
            return;
        }
        axios.get(`${BASE_URL}/${this.props.mapType}/entity/search`, {
            params: {
                term,
                max_result_len: 300
            }
        }).then(response => {
            const data = response.data;
            if (response.status !== 200 || data === undefined || data === null) {
                this.props.showError();
            }
            this.setState({ filteredKeywords: data.map(el => el.text) });
        }).catch(err => {
            this.props.showError();
        })
    }

    clearSearch() {
        this.search('');
    }

    isSearchTermEmpty(term) {
        return term.trim().length === 0;
    }

    updateSearchState(term) {
        if (this.props.mapType === WORDMAP) {
            this.setState({ searchTermWordmap: term });
        } else {
            this.setState({ searchTermDocmap: term });
        }
    }

    render() {
        const noSearchResults = this.state.filteredKeywords && this.state.filteredKeywords.length === 0;
        const highlightedWords = this.props.highlightedWords;
        const keywords = this.props.mapType === WORDMAP ? 'All keywords' : 'All topics';
        const tooltip = this.props.mapType === WORDMAP ? 'Keywords that are most commonly found in the domain area. Click on a keyword to see it\'s usage across the map.' : 'Topics that are most common in the domain area. Click on a topic to see all documents on the map that contain it.';

        return (
            <div className="wrapper" style={this.props.mapType === DOCMAP ? { height: 'calc(100% - 5rem)'} : {}}>
                <div className="d-flex align-items-center">
                    <h4 className="header-medium m-2">{ keywords }</h4>
                    <img src={infoIcon} alt="Info" className="tooltip-container"  title={tooltip}/>
                </div>

                <SearchInput
                    handleOnChange={(event) => this.search(event.target.value)}
                    clearSearch={() => this.clearSearch()}
                    value={this.searchTerm}
                />

                {
                    noSearchResults ? (
                        <div className="d-flex justify-content-center align-items-center h-100" style={{opacity: 0.5}}>
                            No results
                        </div>
                    ) : (
                        <ItemsContainer styles={this.props.mapType === DOCMAP ? { height: '100%' } : {}}>
                            {(this.state.filteredKeywords === null ? this.state.initialState : this.state.filteredKeywords)
                                .map(el =>
                                    <KeywordDetails
                                        key={v4()}
                                        word={el}
                                        isSelected={highlightedWords.map(word => word.keyword).includes(el)}
                                        highlightedWord={highlightedWords.filter(word => el === word.keyword)}
                                        hasClearButton={false}
                                        mapType={this.props.mapType}
                                    />)}
                        </ItemsContainer>
                    )
                }
            </div>
        );
    }
}

KeywordSearch.propTypes = {
    mapType: PropTypes.string,
    highlightedWords: PropTypes.arrayOf(
        PropTypes.shape(
            { keyword: PropTypes.string,
                color: PropTypes.string,
                coords: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number}))}
                )
    )
};

const mapStateToProps = (state, ownProps) => ({
    mapType: ownProps.match.params.mapType,
    highlightedWords: state[ownProps.match.params.mapType].highlightedWords
});

export default connect(mapStateToProps, { showError })(KeywordSearch);
