import {
    BLOCK_UI_COORDS_REQUEST
} from '../actions/actionTypes';

const initialState = {
    blockUiCoordsRequest: {},
};

export default (state=initialState, action) => {
    if (action.type === BLOCK_UI_COORDS_REQUEST) {
        return {
            ...state,
            blockUiCoordsRequest: {...state.blockUiCoordsRequest, [action.payload.word]: action.payload.isBlocked }
        };
    }
    return state;
};
