import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Route} from 'react-router-dom';
import FiltersSidebar from '../filters-sidebar';
import SemanticMap from '../shared/semantic-map';
import HighlightedTermsContainer from '../highlighted-terms-container';
import TopicsSidebar from '../topics-sidebar';

import configs from '../../configs';
const { ROUTE_MAP_TYPE } = configs;
const { WORDMAP, DOCMAP } = configs.CONSTANTS;

class SemanticMapContainer extends React.Component {
    render() {
        let classes = ["main-container"];
        if ((!this.props.filtersSidebarVisible && !this.props.topicsSidebarVisible) || this.props.isMobile) {
            classes.push("main-container-both-collapsed");
        } else if (!this.props.filtersSidebarVisible) {
            classes.push("main-container-left-collapsed");
        } else if (!this.props.topicsSidebarVisible) {
            classes.push("main-container-right-collapsed");
        }

        return (
            <div className={classes.join(' ')}>
                <FiltersSidebar isMobile={this.props.isMobile} />
                <div className="position-relative">
                    <Route exact path={ROUTE_MAP_TYPE}  render={({ match }) => <SemanticMap mapType={this.props.mapType} key={match.params.mapType} />} />
                    <Route exact path={ROUTE_MAP_TYPE}  render={({ match }) => <HighlightedTermsContainer match={match} isMobile={this.props.isMobile} />} />
                </div>
                <TopicsSidebar isMobile={this.props.isMobile} mapType={this.props.mapType} />
            </div>
        );
    }
}

SemanticMapContainer.propTypes = {
    filtersSidebarVisible: PropTypes.bool.isRequired,
    topicsSidebarVisible: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    mapType: PropTypes.oneOf([WORDMAP, DOCMAP]).isRequired
};

const mapStateToProps = state => ({
    filtersSidebarVisible: state.visibility.filtersSidebarVisible,
    topicsSidebarVisible: state.visibility.topicsSidebarVisible,
});

export default connect(mapStateToProps)(SemanticMapContainer);
