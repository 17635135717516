import axios from 'axios';
import configs from '../configs';
const { BASE_URL } = configs;

// This won't work with wildcards for server CORS
// axios.defaults.withCredentials = true;

export default axios.create({
    baseURL: BASE_URL
});
