// This is the place where redux store is born...

import {applyMiddleware, compose, createStore} from "redux";
import reducer from '../reducers';
import thunk from 'redux-thunk';
import {saveState} from "./localStorage";

const devToolsExt = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__() : dummy => dummy;
const store = createStore(reducer, compose(applyMiddleware(thunk), devToolsExt));
store.subscribe(() => {
    saveState({ previousSearch: store.getState().search.previousSearch });
});

export default store;
