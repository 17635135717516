import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 } from 'node-uuid';

import ItemsContainer from '../../shared/items-container';
import SearchInput from '../../shared/search-input';

import {panTo} from '../../../actions';

import infoIcon from '../../../images/infoIcon.svg';
import './styles.css';

import configs from '../../../configs';
const { DOCMAP } = configs.CONSTANTS;


class TopicsSearch extends React.Component {
    state = {
        filteredTopics: null,
        searchTerm: ''
    };

    handleOnChange(term) {
        const filteredTopics = this.props.topics.filter(topic => topic.name.toLowerCase().includes(term.toLowerCase()));
        this.setState({ searchTerm: term.toLowerCase(), filteredTopics });
    }

    render() {
        if (this.props.mapType === DOCMAP) {
            return null;
        }

        const noSearchResults = this.state.filteredTopics && this.state.filteredTopics.length === 0;
        const tooltip = `List of all topics marked on the map.
Click on a topic name to open it on the map.`;

        return (
            <div className="wrapper">
                <div className="d-flex align-items-center">
                    <h4 className="header-medium m-2">All topics</h4>
                    <img src={infoIcon} alt="Info" title={tooltip}/>
                </div>

                <SearchInput
                    handleOnChange={(event) => this.handleOnChange(event.target.value)}
                    clearSearch={() => this.handleOnChange('')}
                    value={this.state.searchTerm}
                />

                {
                    noSearchResults ? (
                        <div className="d-flex justify-content-center align-items-center h-100" style={{opacity: 0.5}}>
                            No results
                        </div>
                    ) : (
                        <ItemsContainer>
                            {this.props.topics &&
                            (this.state.filteredTopics === null ? this.props.topics : this.state.filteredTopics)
                                .slice(0)
                                .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
                                .map((topic, idx) => <div className="topic-search-details"
                                                   key={v4()}
                                                   onClick={() => this.props.panTo(topic)}
                                              >
                                                  {topic.name}
                                              </div>)}
                        </ItemsContainer>
                    )

                }
            </div>
        );
    }
}

TopicsSearch.propTypes = {
    topics: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.number, y: PropTypes.number, importance: PropTypes.number, name: PropTypes.string })),
    mapType: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
    topics: state[ownProps.match.params.mapType].topics,
    mapType: ownProps.match.params.mapType
});

export default connect(mapStateToProps, { panTo })(TopicsSearch);
